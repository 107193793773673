import { useApolloClient } from '@apollo/client'
import { message } from 'antd'
import { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, useHistory } from 'react-router-dom'
import { callLogoutApiForSchool } from '~/services/api/logout'
import {
  AuthContext,
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
} from '../../alpha/core/auth/AuthProvider'
import { useTeacherLinkSchoolMutation } from '../../alpha/core/generated/graphql'
import { findCode } from '../../utils/string'

/**
 * Admin is using this redirection route
 */
const RedirectRoute = ({ ...rest }) => {
  const {
    location: { search },
    push,
  } = useHistory()

  const { t } = useTranslation()

  const [linkSchool] = useTeacherLinkSchoolMutation()
  const auth = useContext(AuthContext)

  const client = useApolloClient()

  const isCode = search?.includes('code=')

  const onCodeSubmit = useCallback(async (code: string) => {
    const variables = {
      input: {
        attributes: {
          familyName: 'ALPHA',
          familyNameHiragana: `AdminLogin_${Date.now()}`,
          givenName: '管理者',
          givenNameHiragana: `AdminLogin_${Date.now()}`,
          email: 'alpha@pestalozzi-tech.com',
        },
        invitationCode: code,
      },
    }

    try {
      const res = await linkSchool({ variables })
      if (res.errors) {
        message.error(
          `${t('エラーが発生しました。')} [${res.errors.join(',')}]`,
        )
        return
      }

      const teacherLinkSchool = res.data?.teacherLinkSchool

      if (!teacherLinkSchool?.token) {
        message.error(t('エラーが発生しました。招待コードを確認してください。'))
        console.error(
          'RedirectRoute - token is required in response!',
          teacherLinkSchool?.token,
          code,
        )
        return
      }

      auth.setAccessToken(teacherLinkSchool.token)
      push('/home')
    } catch (err) {
      message.error(t('エラーが発生しました。招待コードを確認してください。'))
      console.error('when post at linkSchool - RedirectRoute, error:', err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logoutAndLoginWithRequestedCode = useCallback(
    async (code: string) => {
      const authToken = window.localStorage.getItem(
        LOCAL_STORAGE_AUTH_TOKEN_KEY,
      )

      if (authToken) {
        try {
          await callLogoutApiForSchool()
          await client.clearStore()
        } catch (err) {
          message.error(
            `${t('エラーが発生しました。')} [${JSON.stringify(err)}]`,
          )
          push('/')
          console.error('when logout and clearStore, error:', err)
        }
      }

      await onCodeSubmit(code)

      // keep this log
      console.info(
        'When in history searching URL there is a code, logout completely!',
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCodeSubmit],
  )

  useEffect(() => {
    if (isCode) {
      logoutAndLoginWithRequestedCode(findCode(search))
    } else {
      push('/')

      // keep this log
      console.info(
        'when URL searching keyword is not included a code, redirect to / path',
      )
    }
  }, [search])

  return <Route {...rest} render={() => <div>Loading...</div>} />
}

export default RedirectRoute
