import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { UserGender } from '~/alpha/core'
import { TColumn } from '../types'
import { rowDefaultClassName } from '../utils'
import { roundDecimal } from '~/utils/constants'

type ReportTestTableProps = {
  schoolReportTestSummary: any | undefined
  gender: UserGender
  showType: 'average' | 'deviation'
  loading: boolean
  hasEnduranceTest: boolean
}

const roundValue = (v: number | undefined | null) => v ? roundDecimal(v, 2) : '0.0'

/**
 * In `Average and Standard Deviation by Grade` page.
 */
const ReportTestTable = ({
  schoolReportTestSummary,
  gender,
  showType,
  loading,
  hasEnduranceTest,
}: ReportTestTableProps) => {
  const { t } = useTranslation()

  const isMale = gender === UserGender.Male

  const genderClass = isMale ? 'male' : 'female'
  const suffix = showType === 'average' ? 'avg' : 'std'
  const rowColor = isMale ? 'blue' : 'pink'

  const testColumnsClassName = `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`

  const testColumns: TColumn[] = [
    {
      title: t('学年'),
      dataIndex: 'grade',
      key: 'grade',
      className: testColumnsClassName,
    },
    {
      title: t('人数'),
      dataIndex: 'count',
      key: 'count',
      className: testColumnsClassName,
    },
    {
      title: t('身長'),
      dataIndex: `height_${suffix}`,
      key: `height_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('体重'),
      dataIndex: `weight_${suffix}`,
      key: `weight_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('握力'),
      dataIndex: `grip_${suffix}`,
      key: `grip_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('上体起こし'),
      dataIndex: `sitUps_${suffix}`,
      key: `sitUps_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('長座体前屈'),
      dataIndex: `bending_${suffix}`,
      key: `bending_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('反復横跳び'),
      dataIndex: `sideJump_${suffix}`,
      key: `sideJump_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('シャトルラン'),
      dataIndex: `shuttleRun_${suffix}`,
      key: `shuttleRun_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    ...(hasEnduranceTest
      ? [
          {
            title: t('持久走'),
            dataIndex: `enduranceRun_${suffix}`,
            key: `enduranceRun_${suffix}`,
            className: testColumnsClassName,
            render: (v, _) => roundValue(v),
          },
        ]
      : []),
    {
      title: t('50m走'),
      dataIndex: `sprintRun_${suffix}`,
      key: `sprintRun_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('立ち幅跳び'),
      dataIndex: `standingJump_${suffix}`,
      key: `standingJump_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('ボール投げ'),
      dataIndex: `handballThrow_${suffix}`,
      key: `handballThrow_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('得点合計'),
      dataIndex: `points_${suffix}`,
      key: `points_${suffix}`,
      className: testColumnsClassName,
      render: (v, _) => roundValue(v),
    },
  ]

  return (
    <Table
      columns={testColumns}
      dataSource={
        isMale ? schoolReportTestSummary?.male : schoolReportTestSummary?.female
      }
      style={{ width: 1185 }}
      rowKey="grade"
      size="small"
      rowClassName={(_, idx) => {
        return `${rowDefaultClassName} ${
          idx % 2 !== 0
            ? `ant-table-row-${rowColor}-light`
            : `ant-table-row-${rowColor}-dark`
        }`
      }}
      pagination={{
        hideOnSinglePage: true,
        defaultPageSize: 10,
      }}
      loading={loading}
    />
  )
}

export default ReportTestTable
