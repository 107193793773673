import './DropdownMenu.css'

import { UpOutlined } from '@ant-design/icons'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import MenuIcon2 from '~/alpha/components/icons/MenuIcon2'
import { menuList } from '../navigation'

interface SidebarMenuProps {
  selectedMenu: number
}

const DropdownMenu: FunctionComponent<SidebarMenuProps> = ({
  selectedMenu = 1,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const history = useHistory()

  const MenuItems = menuList.map((item) => (
    <div
      className={`text-white dropdown-item font-bold text-center${
        selectedMenu === item.tabIndex ? ' dropdown-item-selected' : ''
      }`}
      key={item.tabIndex}
      onClick={() => history.push(`/${item.key}`)}
    >
      {t(item.title)}
    </div>
  ))

  MenuItems.push(
    <div
      className={`text-white dropdown-item font-bold text-center${
        selectedMenu === 6 ? ' dropdown-item-selected' : ''
      }`}
      key="6"
      onClick={() =>
        window.open(
          'https://alpha2019.notion.site/ALPHA-739c7668999041d298e1e0af147e17e9',
          '_blank',
        )
      }
    >
      <span style={{ whiteSpace: 'pre-line' }}>{t('使い方')}</span>
    </div>,

    <div
      className={`text-white dropdown-item font-bold text-center${
        selectedMenu === 8 ? ' dropdown-item-selected' : ''
      }`}
      key="8"
      onClick={() =>
        window.open('https://pestalozzi-tech.com/contact/', '_blank')
      }
    >
      <span style={{ whiteSpace: 'pre-line' }}>{t('問い合わせ')}</span>
    </div>,

    <div
      className={`text-white dropdown-item font-bold text-center${
        selectedMenu === 7 ? ' dropdown-item-selected' : ''
      }`}
      key="7"
      onClick={() => history.push('/logout')}
    >
      <span style={{ whiteSpace: 'pre-line' }}>{t('ログアウト')}</span>
    </div>,
  )

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdown = (event: Event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as HTMLButtonElement)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    // Add event listener to detect clicks outside of the dropdown
    document.addEventListener('mousedown', closeDropdown)

    return () => {
      // Cleanup the event listener when the component unmounts
      document.removeEventListener('mousedown', closeDropdown)
    }
  }, [])

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <div className="dropdown-button px-4" onClick={toggleDropdown}>
        <MenuIcon2 />
      </div>

      <div
        className={`dropdown-menu bg-black flex flex-col transition-opacity duration-200 ease-in-out ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        {MenuItems}

        <div
          className="dropdown-item-close cursor-pointer flex items-center justify-center bg-white hover:bg-blue-50"
          onClick={() => {
            setIsOpen(false)
          }}
        >
          <UpOutlined className="px-2" />
          {t('閉じる')}
        </div>
      </div>
    </div>
  )
}

export default DropdownMenu
