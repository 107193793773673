import './MoviePage.css'

import { useTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
import { useAlphaStore } from '~/context'
import NextIcon from '../icons/NextIcon'
import { Dashboard } from '../layout/Dashboard'

const MoviePage = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  return (
    <Dashboard selectedMenu={5} navbar={t('動画')}>
      <div className="grid-cols-2 grid mt-14 gap-4" style={{ color: 'black' }}>
        <div className="flex justify-end cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/exercises')}
          >
            <div className="flex justify-between">
              <img
                src="movie_icon.png"
                alt=""
                style={{ width: 54, height: 45, marginBottom: 18.5 }}
              />
              <NextIcon />
            </div>

            <div className="h-60px text-lg font-bold">
              {t('授業で使える運動コンテンツ一覧')}
            </div>

            <div>
              {t(
                '授業でご使用いただける運動コンテンツを動画で紹介しています。',
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-start cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/exercise_content')}
          >
            <div className="flex justify-between">
              <img
                src="chart_icon.png"
                alt=""
                style={{ width: 45, height: 45, marginBottom: 18.5 }}
              />
              <NextIcon />
            </div>

            <div className="h-60px text-lg font-bold">
              {t('集計結果から最適化された運動コンテンツ')}
            </div>

            <div>
              {t(
                '授業でご使用いただける運動コンテンツを動画で紹介しています。',
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-2 cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/exercise_program')}
          >
            <div className="flex justify-between">
              <img
                src="run_icon.png"
                alt=""
                style={{ width: 49, height: 65, marginBottom: 9 }}
              />
              <NextIcon />
            </div>

            <div className="h-60px text-lg font-bold">
              {t('個人でできる運動プログラム')}
            </div>

            <div>
              {t(
                '生徒が個人で実施することができる運動プログラムを動画で紹介しています。',
                { student: isElementarySchool ? '児童' : '生徒' },
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-start mt-2 cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/advise')}
          >
            <div className="flex justify-between">
              <img
                src="shoe_icon.png"
                alt=""
                style={{ width: 70, height: 65, marginBottom: 9 }}
              />
              <NextIcon />
            </div>

            <div className="h-60px text-lg font-bold">
              {t('体力テストお手本動画')}
            </div>

            <div>
              {t(
                '各体力テスト種目の実施方法やコツについて解説する動画を紹介しています。',
              )}
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default MoviePage
