import axios from 'axios'

export const fetchSchoolClasses = (
  schoolId: string,
  testYear: number,
): Promise<{ schoolClasses: number[] }> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_REST_API_URL}/alpha/max-class`, {
        schoolId,
        testYear,
      })
      .then((res: { data: { maxClass: number | undefined } }) => {
        const maxClass = res.data.maxClass

        const classes = maxClass
          ? Array.from({ length: maxClass }, (_, i) => i + 1)
          : []
        resolve({ schoolClasses: classes })
      })
  })
}
