export const getFullName = (
  familyName: string | undefined | null,
  givenName: string | undefined | null,
  isUsingJp: boolean,
): string => {
  const familyNameStr = familyName || ''
  const givenNameStr = givenName || ''
  const spaceBetweenName = isUsingJp ? '' : ' '

  return `${familyNameStr}${spaceBetweenName}${givenNameStr}`
}
