/**
 * @example
 * "123-444-5556"
 * "12-4442-5556"
 * "1234567890"
 * "12345678901"
 * "111"
 * "---"
 */
export const dashSymbolOrNumberRegex = /^[\d-]+$/

export const dashSymbolOnlyRegex = /^-+$/

export const checkIfValidPhone = (
  input: string | undefined | null,
): boolean => {
  const inputStr = input ?? ''

  return (
    dashSymbolOrNumberRegex.test(inputStr) &&
    !dashSymbolOnlyRegex.test(inputStr)
  )
}
