import { SchoolReportTestByGrade, TestResult } from '~/alpha/core'
import { QuestionTestResult } from '~/types/api/survey'

/**
 * Some questions are required, some questions are optional.
 * When all required questions are answered, status is completed survey.
 *
 * @returns true if status is completed survey.
 */
export const checkIfCompletedSurvey = (
  totalQuestionnaires: number[],
  studentTestResults:
    | SchoolReportTestByGrade[]
    | TestResult[]
    | QuestionTestResult[]
    | undefined,
  isOptionalQuestions: boolean[] | undefined,
): boolean => {
  if (!studentTestResults || studentTestResults.length === 0) return false

  let isCompletedSurvey = false

  const questions = (
    studentTestResults[0] as TestResult | QuestionTestResult | undefined
  )?.questionnaireV3

  if (questions) {
    isCompletedSurvey = !totalQuestionnaires.some((id) => {
      return (questions[`q${id}`] === undefined || questions[`q${id}`] === null) && !isOptionalQuestions?.[id - 1]
    })
  }

  return isCompletedSurvey
}
