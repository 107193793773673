import './OverallTable.css'

import { Col, Row, Table } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UserGender } from '~/alpha/core'
import { TColumn } from '../types'
import { rowDefaultClassName } from '../utils'
import { defPagination } from './utils/const'
import { getDataSrc } from './utils/data'
import { MaleFemaleOrAllAvgReport } from './utils/type'

interface OverallTableProps {
  schoolReportTestSummary: undefined | MaleFemaleOrAllAvgReport
  loading: boolean
  /**
   * `undefined` means all, both MALE and FEMALE.
   */
  gender: UserGender | undefined
  isUsingJp: boolean
}

const OverallTable = ({
  schoolReportTestSummary,
  gender,
  loading,
  isUsingJp,
}: OverallTableProps) => {
  const { t } = useTranslation()

  const textShow =
    gender === UserGender.Male
      ? t('男子')
      : gender === UserGender.Female
      ? t('女子')
      : t('学年全体')

  const genderClass =
    gender === UserGender.Male
      ? 'male'
      : gender === UserGender.Female
      ? 'female'
      : 'not'

  const rowColor =
    gender === UserGender.Male
      ? 'blue'
      : gender === UserGender.Female
      ? 'pink'
      : 'orange'

  const getClassName = (_: unknown, idx: number) => {
    return `${rowDefaultClassName} ${
      idx % 2 === 0
        ? `ant-table-row-${rowColor}-dark`
        : `ant-table-row-${rowColor}-light`
    }`
  }

  const dataSrc = useMemo(
    () => getDataSrc(schoolReportTestSummary, gender, t),
    [gender, schoolReportTestSummary, t],
  )

  const countColumnsClassName = `w-20 h-6 font-black ant-table-${genderClass}-test${
    isUsingJp ? '' : ' table-head-test-en'
  }`

  const countColumns: TColumn[] = [
    {
      title: 'A',
      dataIndex: 'rank_a',
      key: 'rank_a',
      className: countColumnsClassName,
    },
    {
      title: 'B',
      dataIndex: 'rank_b',
      key: 'rank_b',
      className: countColumnsClassName,
    },
    {
      title: 'C',
      dataIndex: 'rank_c',
      key: 'rank_c',
      className: countColumnsClassName,
    },
    {
      title: 'D',
      dataIndex: 'rank_d',
      key: 'rank_d',
      className: countColumnsClassName,
    },
    {
      title: 'E',
      dataIndex: 'rank_e',
      key: 'rank_e',
      className: countColumnsClassName,
    },
  ]

  const ratioColumns: TColumn[] = [
    {
      title: 'A',
      dataIndex: 'ranking_count',
      key: 'rank_a',
      className: countColumnsClassName,
      render: (v, r) => {
        if (!v || !r.rank_a) return '0%'
        return `${((r.rank_a / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'B',
      dataIndex: 'ranking_count',
      key: 'rank_b',
      className: countColumnsClassName,
      render: (v, r) => {
        if (!v || !r.rank_b) return '0%'
        return `${((r.rank_b / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'C',
      dataIndex: 'ranking_count',
      key: 'rank_c',
      className: countColumnsClassName,
      render: (v, r) => {
        if (!v || !r.rank_c) return '0%'
        return `${((r.rank_c / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'D',
      dataIndex: 'ranking_count',
      key: 'rank_d',
      className: countColumnsClassName,
      render: (v, r) => {
        if (!v || !r.rank_d) return '0%'
        return `${((r.rank_d / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'E',
      dataIndex: 'ranking_count',
      key: 'rank_e',
      className: countColumnsClassName,
      render: (v, r) => {
        if (!v || !r.rank_e) return '0%'
        return `${((r.rank_e / v) * 100).toFixed(2)}%`
      },
    },
  ]

  const overallColumnsClassName = `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test${
    isUsingJp ? '' : ' table-head-test-en'
  }`

  const overallColumns: TColumn[] = [
    {
      title: t('学年'),
      dataIndex: 'grade',
      key: 'grade',
      className: overallColumnsClassName,
    },
    {
      title: t('全種目実施人数'),
      dataIndex: 'ranking_count',
      key: 'ranking_count',
      className: overallColumnsClassName,
    },
  ]

  return (
    <Row gutter={4}>
      <Col span={4}>
        <div
          className={`h-6 mb-2 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {textShow}
        </div>
        <Table
          columns={overallColumns}
          dataSource={dataSrc}
          style={{ width: 200 }}
          rowKey="grade"
          size="small"
          rowClassName={getClassName}
          pagination={defPagination}
          loading={loading}
        />
      </Col>

      <Col span={10}>
        <div
          className={`h-6 mb-2 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {t('人数')}
        </div>
        <Table
          columns={countColumns}
          dataSource={dataSrc}
          style={{ width: 500 }}
          rowKey="grade"
          size="small"
          rowClassName={getClassName}
          pagination={defPagination}
          loading={loading}
        />
      </Col>

      <Col span={10}>
        <div
          className={`h-6 mb-2 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {t('割合')}
        </div>
        <Table
          columns={ratioColumns}
          dataSource={dataSrc}
          style={{ width: 500 }}
          rowKey="grade"
          size="small"
          rowClassName={getClassName}
          pagination={defPagination}
          loading={loading}
        />
      </Col>
    </Row>
  )
}

export default OverallTable
