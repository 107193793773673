import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAlphaStore } from '~/context'
import NextIcon from '../icons/NextIcon'
import { Dashboard } from '../layout/Dashboard'

/**
 * /information
 */
const InformationPage = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { school } = useAlphaStore()

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  return (
    <Dashboard selectedMenu={2} navbar={t('情報')}>
      <div className="grid-cols-2 grid mt-14 gap-4" style={{ color: 'black' }}>
        <div className="flex justify-end">
          <div
            className="cursor-pointer rounded"
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/student_list')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('生徒登録', {
                  student: isElementarySchool ? '児童' : '生徒',
                })}
              </span>
              <NextIcon />
            </div>
            <div>
              <span>
                {t(
                  '生徒の登録をExcelファイルで行うことができます。利用開始時に登録をしてください。',
                  { student: isElementarySchool ? '児童' : '生徒' },
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-start">
          <div
            className="cursor-pointer rounded"
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/school_year_info')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('サインインコード')}
              </span>
              <NextIcon />
            </div>
            <div>
              <span>
                {t(
                  '登録した生徒がログインするためのサインインコードを表示します。',
                  { student: isElementarySchool ? '児童' : '生徒' },
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-2">
          <div
            className="cursor-pointer rounded"
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/students')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('修正・削除・追加')}
              </span>
              <NextIcon />
            </div>
            <div>
              <span>
                {t('登録した生徒の修正・削除及び、追加の登録ができます。', {
                  student: isElementarySchool ? '児童' : '生徒',
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default InformationPage
