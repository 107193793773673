import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { MetaStudent } from '~/alpha/core'
import { getFullName } from '~/school-utils/name'
import isJapanese from '~/utils/isJapanese'

export const PrintingFor9 = ({
  students,
  schoolGrade,
  schoolClass,
  code,
}: {
  students: MetaStudent[]
  schoolGrade: number
  schoolClass: number
  code: string
}) => {
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  return (
    <div className="p-4 pt-4 mx-5 page-break-auto">
      <div className="grid grid-cols-3 gap-5 py-2">
        {students.map(
          ({
            attendanceNumber,
            familyName,
            givenName,
            studentInvitationCode,
          }) => (
            <div
              className="p-2 border grid justify-center bg-white"
              key={`${schoolGrade}${schoolClass}${attendanceNumber}`}
            >
              <div className="flex flex-col space-y-2 items-center">
                <QRCode
                  value={`${code}${attendanceNumber}${
                    studentInvitationCode ? `_${studentInvitationCode}` : ''
                  }`}
                />

                <div className="text-10px">{`${code}${attendanceNumber}${
                  studentInvitationCode ? `_${studentInvitationCode}` : ''
                }`}</div>

                <div className={isUsingJp ? 'text-lg' : ''}>
                  {`${t('g年生', {
                    count: schoolGrade,
                    ordinal: !isUsingJp,
                  })} ${t('個別組', {
                    count: schoolClass,
                    ordinal: !isUsingJp,
                  })} ${t('n番', { num: attendanceNumber })}`}
                </div>

                <div>{`${t('名前')}${t('：')}${getFullName(
                  familyName,
                  givenName,
                  isUsingJp,
                )}`}</div>
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  )
}
