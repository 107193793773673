import { Button, Grid, Modal, Select, Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { UserRole, useTeachersLazyQuery } from '@alpha/core'
import { CloseOutlined } from '@ant-design/icons'
import { useAlphaStore } from '~/context'
import { currentYear } from '~/utils/constants'
import { Dashboard } from '../layout/Dashboard'

const { Option } = Select
const { useBreakpoint } = Grid

type TeacherData = {
  __typename?: 'User'
  _id: string
  attributes: {
    __typename?: 'UserAttributes'
    givenName?: string | null
    givenNameHiragana?: string | null
    familyName?: string | null
    familyNameHiragana?: string | null
    email?: string | null
    role?: UserRole | null
    schoolGrade?: number | null
    schoolClass?: number | null
  }
}[]

/**
 * Not showing in menu.
 */
const TeacherListPage = () => {
  const { teacher, school, schoolCategoryCode } = useAlphaStore()
  const testYear = currentYear

  const [data, setData] = useState<TeacherData | undefined>()

  const [selectedTeacher, setSelectedTeacher] = useState<
    TeacherData[0] | undefined
  >()
  const [selectedRole, setSelectedRole] = useState<UserRole>(
    UserRole.SchoolAdmin,
  )
  const [selectedGrade, setSelectedGrade] = useState<number>(1)
  const [selectedClass, setSelectedClass] = useState<number>(1)

  const [showEditModal, setShowEditModal] = useState(false)

  const [getTeachers, { data: teacherData, loading }] = useTeachersLazyQuery({
    fetchPolicy: 'network-only',
  })

  // const [updateTeacherRole] = useUpdateTeacherRoleMutation()

  const handleEditTeacher = useCallback(
    (_teacher) => {
      if (teacher?.email === _teacher.email) return
      setSelectedTeacher(_teacher)
      setSelectedRole(_teacher.attributes.role)
      setShowEditModal(true)
    },
    [teacher],
  )

  // async function handleEditTeacherSubmit() {
  //   if (!school) {
  //     message.error('[school is not found]')
  //     console.error('handleEditTeacherSubmit, error: school is not found')
  //     return
  //   }

  //   if (!selectedTeacher?.attributes.email) {
  //     message.error('[selectedTeacher email is not found]')
  //     return
  //   }

  //   const input: UpdateTeacherRoleInput = {
  //     role: selectedRole,
  //     schoolId: school._id,
  //     teacherEmail: selectedTeacher.attributes.email,
  //     schoolGrade: ['GRADE_ADMIN', 'TEACHER'].includes(selectedRole)
  //       ? selectedGrade
  //       : undefined,
  //     schoolClass: ['TEACHER'].includes(selectedRole)
  //       ? selectedClass
  //       : undefined,
  //   }
  //   await updateTeacherRole({ variables: { input } })
  //   message.success('更新しました')
  //   refetch()
  //   setShowEditModal(false)
  //   setSelectedTeacher(undefined)
  //   setSelectedRole(UserRole.SchoolAdmin)
  // }

  // getTeachers
  useEffect(() => {
    if (!school) return

    getTeachers({
      variables: {
        input: {
          schoolId: school._id,
          testYear,
        },
      },
    })
  }, [school, testYear, getTeachers])

  // setData
  useEffect(() => {
    if (!teacherData?.teachers?.data) {
      setData([])
      return
    }

    setData(teacherData.teachers.data)
  }, [teacherData])

  const useTableColumns: ColumnType<any>[] = useMemo(() => {
    const defaultColumns: any[] = [
      {
        title: '名前',
        dataIndex: ['attributes'],
        key: 'name',
        render: (v) => (
          <div className="whitespace-nowrap">{`${v.familyName ?? ''}　${
            v.givenName ?? ''
          }`}</div>
        ),
      },
      {
        title: 'なまえ',
        dataIndex: ['attributes'],
        key: 'nameHiragana',
        render: (v) => (
          <div className="whitespace-nowrap">{`${v.familyNameHiragana ?? ''}　${
            v.givenNameHiragana ?? ''
          }`}</div>
        ),
      },
      {
        title: 'メールアドレス',
        dataIndex: ['attributes', 'email'],
      },
    ]

    return defaultColumns
  }, [handleEditTeacher, teacher])

  const title = <div className="theme-nav-title">先生一覧</div>

  const screens = useBreakpoint()

  const modalProps = {
    visible: showEditModal,
    width: screens.xxl ? '70vw' : screens.lg ? '85vw' : '90vw',
    onCancel: () => {
      setShowEditModal(false)
    },
    closeIcon: <CloseOutlined className="text-2xl color-blue" />,
    footer: null,
  }

  return (
    <Dashboard selectedMenu={2} navbar={title}>
      <div className="flex justify-center">
        <div className="inline-block mt-14">
          <div className="">登録人数　{data?.length}</div>

          <Table
            columns={useTableColumns}
            dataSource={data}
            loading={loading}
            size="small"
            style={{ minWidth: 850 }}
            rowKey="_id"
            bordered={true}
            pagination={{
              pageSize: 999,
              showSizeChanger: false,
              hideOnSinglePage: true,
              position: ['bottomCenter'],
            }}
          />
        </div>
      </div>
      {selectedTeacher && (
        <Modal key={`q^${selectedTeacher._id}`} {...modalProps}>
          <div className="my-8">
            <Table
              columns={[
                {
                  title: '名前',
                  dataIndex: ['attributes', 'familyName'],
                },
                {
                  title: '権限',
                  key: 'role',
                  render: (v) => (
                    <div className="flex flex-row space-x-4">
                      <Select
                        className="w-64"
                        defaultValue={v.attributes.role}
                        onChange={setSelectedRole}
                      >
                        <Option value="SCHOOL_ADMIN">全体管理者</Option>
                        <Option value="GRADE_ADMIN">学年管理者</Option>
                        <Option value="TEACHER">担任</Option>
                      </Select>

                      {['GRADE_ADMIN', 'TEACHER'].includes(selectedRole) && (
                        <Select
                          className="w-30"
                          value={selectedGrade}
                          onChange={setSelectedGrade}
                        >
                          {schoolCategoryCode === 'B1'
                            ? [1, 2, 3, 4, 5, 6].map((grade) => (
                                <Option value={grade} key={grade}>
                                  {grade}学年
                                </Option>
                              ))
                            : [1, 2, 3].map((grade) => (
                                <Option value={grade} key={grade}>
                                  {grade}学年
                                </Option>
                              ))}
                        </Select>
                      )}
                      {['TEACHER'].includes(selectedRole) && (
                        <Select
                          className="w-30"
                          value={selectedClass}
                          onChange={setSelectedClass}
                        >
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_class) => (
                            <Option value={_class} key={_class}>
                              {_class}組
                            </Option>
                          ))}
                        </Select>
                      )}
                    </div>
                  ),
                },
              ]}
              dataSource={[selectedTeacher]}
              size="small"
              rowKey="_id"
              bordered={true}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>
          <div className="flex justify-center">
            <Button
              type="primary"
              className="w-64"
              // onClick={() => handleEditTeacherSubmit()}
            >
              変更
            </Button>
          </div>
        </Modal>
      )}
    </Dashboard>
  )
}

export default TeacherListPage
