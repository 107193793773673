import { SchoolCategoryCode, TUserGender } from '~/utils/constants'
import prefectureAverage2022 from '~/utils/prefecture-average/prefecture-average-2022'
import {
  IPrefectureAverage,
  prefectureCodes,
} from '~/utils/prefecture-average/prefectureCodes'

export const getPrefectureAverage = (
  prefectureCode: number | undefined,
  gender: TUserGender | undefined,
  schoolGrade: number | undefined | null,
  schoolCategoryCode: string | undefined,
): false | IPrefectureAverage | undefined => {
  const prefectureName = prefectureCodes.find(
    ([code]) => code === prefectureCode,
  )?.[1]

  if (!prefectureName) {
    console.error('getPrefectureAverage - prefecture not found!')
    return false
  }

  if (!prefectureAverage2022[prefectureName]) return false

  const prefectureAverageByGender: IPrefectureAverage[] =
    prefectureAverage2022[prefectureName][gender === 'MALE' ? 'male' : 'female']

  if (!prefectureAverageByGender) {
    throw new Error(
      'getPrefectureAverage - prefectureAverageByGender not found!',
    )
  }

  const prefectureAverage = prefectureAverageByGender.find(
    (avg) =>
      avg.grade === schoolGrade &&
      avg.schoolCategoryCode === (schoolCategoryCode as SchoolCategoryCode),
  )

  return prefectureAverage
}
