import { useEffect, useRef, useState } from 'react'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '~/alpha/core'
import { clearStorageAndAuthToken } from '~/school-utils/storages'
import { Surveys } from '~/types/api/survey'
import { CommonError } from '~/utils/error'
import Api from '../utils/api'

export const useAuthApiGet = <TResponse = any>(
  apiUrl: string,
): {
  data: TResponse | undefined
  loading: boolean
  error: CommonError | undefined
} => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

  const [data, setData] = useState<TResponse | undefined>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<CommonError | undefined>()

  const fetchedRef = useRef(false)

  useEffect(() => {
    if (authToken && !fetchedRef.current) {
      // biome-ignore lint/suspicious/noAsyncPromiseExecutor: <explanation>
      new Promise(async () => {
        try {
          setLoading(true)
          const { data }: { data: TResponse } = await Api.get(apiUrl)
          setData(data)
          setError(undefined)
        } catch (err: any) {
          console.error(`apiUrl: ${apiUrl} - error:`, err)

          if (apiUrl.includes('/me') && err.response?.status === 401) {
            clearStorageAndAuthToken()

            // keep this log
            console.warn(
              'When response status is unauthorized (401) at useAuthApiGet, logout!',
            )
          }

          setError(err)
        } finally {
          fetchedRef.current = false
          setLoading(false)
        }
      })
    } else {
      // keep this log
      console.log(
        'Not found auth token and fetchedRef.current is true when useAuthApiGet, do nothing!',
      )
      setLoading(false)
      setError(undefined)
    }
  }, [apiUrl, authToken])

  return {
    data: authToken ? data : undefined,
    loading,
    error,
  }
}

interface QueryResult<T> {
  data: T | undefined
  loading: boolean
  error: CommonError | undefined
}

export const useQuery = <T>({
  queryFn,
}: {
  queryFn: () => Promise<T>
}): QueryResult<T> => {
  const [data, setData] = useState<T | undefined>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<CommonError | undefined>()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const res = await queryFn()
        setData(res)
        setError(undefined)
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      } catch (err: any) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    data,
    loading,
    error,
  }
}

export const useSchoolGradesApiQuery = () =>
  useAuthApiGet<{ data: { grade: number }[] }>('/alpha/v1/school/grade-ids')

export const useSchoolSurveysApiQuery = ({
  testYear,
  schoolGrade,
  schoolClass,
  limit,
  offset,
}: {
  testYear: number
  schoolGrade: number
  schoolClass: number
  limit: number
  offset: number
}) => {
  return useAuthApiGet<Surveys>(
    `/alpha/v1/school/survey?testYear=${testYear}&schoolGrade=${schoolGrade}&schoolClass=${schoolClass}&offset=${offset}&limit=${limit}`,
  )
}
