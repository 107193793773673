import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { getFullName } from '~/school-utils/name'
import isJapanese from '~/utils/isJapanese'

export const PrintingFor1 = ({
  attendanceNumber,
  familyName,
  givenName,
  studentInvitationCode,
  schoolGrade,
  schoolClass,
  code,
}: {
  attendanceNumber: number
  familyName: string | null | undefined
  givenName: string | null | undefined
  studentInvitationCode: string | null | undefined
  schoolGrade: number
  schoolClass: number
  code: string
}) => {
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  return (
    <div className="flex justify-center items-center bg-white h-screen">
      <div className="flex flex-col space-y-2 items-center">
        <QRCode
          value={`${code}${attendanceNumber}${
            studentInvitationCode ? `_${studentInvitationCode}` : ''
          }`}
        />

        <div className="text-sm">{`${code}${attendanceNumber}${
          studentInvitationCode ? `_${studentInvitationCode}` : ''
        }`}</div>

        <div className="text-lg">
          {`${t('g年生', {
            count: schoolGrade,
            ordinal: !isUsingJp,
          })} ${t('個別組', {
            count: schoolClass,
            ordinal: !isUsingJp,
          })} ${t('n番', { num: attendanceNumber })}`}
        </div>

        <div>{`${t('名前')}${t('：')}${getFullName(
          familyName,
          givenName,
          isUsingJp,
        )}`}</div>
      </div>
    </div>
  )
}
