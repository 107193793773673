import type { TFunction } from 'i18next'

const columns = (t: TFunction<'translation', undefined>, isUsingJp: boolean) =>
  [
    {
      title: t('学年'),
      dataIndex: 'schoolGrade',
      key: 'schoolGrade',
      className: 'text-center-f',
    },
    {
      title: t('組'),
      dataIndex: 'schoolClass',
      key: 'schoolClass',
      className: 'text-center-f',
    },
    {
      title: t('出席番号'),
      dataIndex: 'schoolAttendanceNumber',
      key: 'schoolAttendanceNumber',
      className: 'text-center-f',
    },
    {
      title: t('姓'),
      dataIndex: 'familyName',
      key: 'familyName',
      className: 'text-center-f',
    },
    {
      title: t('名'),
      dataIndex: 'givenName',
      key: 'givenName',
      className: 'text-center-f',
    },
    isUsingJp && {
      title: t('せい'),
      dataIndex: 'familyNameHiragana',
      key: 'familyNameHiragana',
      className: 'text-center-f',
    },
    isUsingJp && {
      title: t('めい'),
      dataIndex: 'givenNameHiragana',
      key: 'givenNameHiragana',
      className: 'text-center-f',
    },
    {
      title: t('性別'),
      dataIndex: 'gender',
      key: 'gender',
      className: 'text-center-f',
      render: (v: string) => (
        <div>{v ? (v === 'MALE' ? t('Male') : t('Female')) : null}</div>
      ),
    },
    {
      title: t('生年月日（年）'),
      dataIndex: 'yearBirth',
      key: 'yearBirth',
      className: 'text-center-f',
    },
    {
      title: t('生年月日（月）'),
      dataIndex: 'monthBirth',
      key: 'monthBirth',
      className: 'text-center-f',
    },
    {
      title: t('生年月日（日）'),
      dataIndex: 'dayBirth',
      key: 'dayBirth',
      className: 'text-center-f',
    },
  ].filter(Boolean)

export const tableColumns = (
  t: TFunction<'translation', undefined>,
  isUsingJp: boolean,
) => columns(t, isUsingJp)
