import React, { ErrorInfo } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { reportErrorSlack } from '~/utils/reportError'
import ErrorFallback from './components/cards/ErrorFallback'
import { initializeInterceptors } from './school-utils/interceptors'

// WARNING: This order is important! index.css -> antd.css
import './index.css'
// WARNING: This order is important!
import './antd.css'

import moment from 'moment'
import 'moment/locale/ja'

// import i18n (needs to be bundled ;))
import App from './App'
import './i18n'

initializeInterceptors()

moment.locale('ja')

ReactDOM.render(
  <React.StrictMode>
    <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error: Error, info: ErrorInfo) => {
        reportErrorSlack(error, info)
      }}
    >
      <App />
      {/* <MaintainNoticePage /> */}
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)
