import './statistic.css'

import { Row } from 'antd'
import { TFunction } from 'i18next'
import { IPrefectureAverage } from '~/utils/prefecture-average/prefectureCodes'

const StatisticHeader = ({
  prefectureAverage,
  t,
  isUsingJp,
}: {
  prefectureAverage: false | IPrefectureAverage | undefined
  t: TFunction<'translation', undefined>
  isUsingJp: boolean
}) => {
  const titleClassName = isUsingJp
    ? 'title-underline flex items-center justify-center font-black h-7'
    : 'title-underline flex items-center justify-center font-black h-7 text-10px'

  const twoLineTitleClassName = isUsingJp
    ? 'title-underline flex items-center justify-center font-black h-7 text-center'
    : 'title-underline flex items-center justify-center font-black h-7 text-center text-10px pb-2'

  return (
    <>
      <Row className="flex w-full text-black mt-2">
        <div className={titleClassName}>{t('種目')}</div>
        <div className="w-4" />

        <div className={titleClassName}>{t('得点')}</div>
        <div className="w-4" />
        {prefectureAverage && (
          <>
            <div className={twoLineTitleClassName}>{t('県平均')}</div>
            <div className="w-4" />
          </>
        )}
        <div className={twoLineTitleClassName}>{t('全国平均')}</div>
        <div className="w-4" />

        <div
          className={
            'flex items-center justify-center font-black h-7 px-2 flex-grow'
          }
        >
          {t('コメント')}
        </div>
        <div className="w-4" />

        <div
          className={
            'flex items-center justify-center font-black h-7 ml-auto w-1/4'
          }
        >
          {t('レーダーチャート')}
        </div>
      </Row>

      <Row>
        <div className="title-underline text-center font-black border-b-2 border-primary v-underlined" />
        <div className="w-4" />

        <div className="title-underline text-center font-black border-b-2 border-primary v-underlined" />
        <div className="w-4" />

        {prefectureAverage && (
          <>
            <div className="title-underline text-center font-black border-b-2 border-primary v-underlined" />
            <div className="w-4" />
          </>
        )}

        <div className="title-underline text-center font-black border-b-2 border-primary v-underlined" />
        <div className="w-4" />

        <div
          className={
            'text-center font-black border-b-2 border-primary flex-grow'
          }
        />
        <div className="w-4" />

        <div
          className={
            'text-center font-black border-b-2 border-primary w-1/4 ml-auto'
          }
        />
      </Row>
    </>
  )
}

export default StatisticHeader
