import type { ColumnType } from 'antd/es/table'
import type { TFunction } from 'i18next'

export const testResultTableColumns = (
  t: TFunction<'translation', undefined>,
): ColumnType<any>[] => [
  {
    title: t('学年'),
    dataIndex: 'schoolGrade',
    key: 'schoolGrade',
    className: 'text-center-f',
  },
  {
    title: t('組'),
    dataIndex: 'schoolClass',
    key: 'schoolClass',
    className: 'text-center-f',
  },
  {
    title: t('出席番号'),
    dataIndex: 'schoolAttendanceNumber',
    key: 'schoolAttendanceNumber',
    className: 'text-center-f',
  },
  {
    title: t('身長'),
    dataIndex: 'height',
    key: 'height',
    className: 'text-center-f',
  },
  {
    title: t('体重'),
    dataIndex: 'weight',
    key: 'weight',
    className: 'text-center-f',
  },
  {
    title: t('握力右1'),
    dataIndex: 'gripRight1',
    key: 'gripRight1',
    className: 'text-center-f',
  },
  {
    title: t('握力右2'),
    dataIndex: 'gripRight2',
    key: 'gripRight2',
    className: 'text-center-f',
  },
  {
    title: t('握力左1'),
    dataIndex: 'gripLeft1',
    key: 'gripLeft1',
    className: 'text-center-f',
  },
  {
    title: t('握力左2'),
    dataIndex: 'gripLeft2',
    key: 'gripLeft2',
    className: 'text-center-f',
  },
  {
    title: t('上体起こし'),
    dataIndex: 'sitUps',
    key: 'sitUps',
    className: 'text-center-f',
  },
  {
    title: t('長座体前屈1'),
    dataIndex: 'bending1',
    key: 'bending1',
    className: 'text-center-f',
  },
  {
    title: t('長座体前屈2'),
    dataIndex: 'bending2',
    key: 'bending2',
    className: 'text-center-f',
  },
  {
    title: t('反復横跳び1'),
    dataIndex: 'sideJump1',
    key: 'sideJump1',
    className: 'text-center-f',
  },
  {
    title: t('反復横跳び2'),
    dataIndex: 'sideJump2',
    key: 'sideJump2',
    className: 'text-center-f',
  },
  {
    title: t('シャトルラン'),
    dataIndex: 'shuttleRun',
    key: 'shuttleRun',
    className: 'text-center-f',
  },
  {
    title: t('50m走'),
    dataIndex: 'sprintRun',
    key: 'sprintRun',
    className: 'text-center-f',
  },
  {
    title: t('立ち幅跳び1'),
    dataIndex: 'standingJump1',
    key: 'standingJump1',
    className: 'text-center-f',
  },
  {
    title: t('立ち幅跳び2'),
    dataIndex: 'standingJump2',
    key: 'standingJump2',
    className: 'text-center-f',
  },
  {
    title: t('ボール投げ1'),
    dataIndex: 'handballThrow1',
    key: 'handballThrow1',
    className: 'text-center-f',
  },
  {
    title: t('ボール投げ2'),
    dataIndex: 'handballThrow2',
    key: 'handballThrow2',
    className: 'text-center-f',
  },
  {
    title: t('持久走（分）'),
    dataIndex: 'runningTime',
    key: 'runningTime',
    className: 'text-center-f',
  },
  {
    title: t('持久走（秒）'),
    dataIndex: 'runningTimeSeconds',
    key: 'runningTimeSeconds',
    className: 'text-center-f',
  },
]
