import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Tooltip,
} from 'recharts'
import ScreenLoading from '~/components/shared/loading/ScreenLoading'
import { checkTestScore, computeTestScore } from '../utils'

/**
 * For each grade, has male and female students charts.
 */
const ReportScoreGraphs = ({
  chartSummary,
  grades,
  loading,
  hasEnduranceTest,
  isUsingJp,
}: {
  chartSummary: any
  grades:
    | {
        data: {
          grade: number
        }[]
      }
    | undefined

  loading: boolean
  hasEnduranceTest: boolean
  isUsingJp: boolean
}) => {
  const { t } = useTranslation()

  const [data, setData] = useState<
    | null
    | {
        grade: number
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        male: { chart: any[] }
        female: { chart: any[] }
      }[]
  >(null)

  const tests = {
    totalScore: { title: t('得点合計') },
    height: { title: t('身長') },
    weight: { title: t('体重') },
    grip: { title: t('握力') },
    sitUps: { title: t('上体起こし') },
    bending: { title: t('長座体前屈') },
    sideJump: { title: t('反復横跳び') },
    shuttleRun: { title: t('シャトルラン') },
    ...(hasEnduranceTest ? { enduranceRun: { title: t('持久走') } } : {}),
    sprintRun: { title: t('50m走') },
    standingJump: { title: t('立ち幅跳び') },
    handballThrow: { title: t('ボール投げ') },
  } as const

  const testKeys = Object.keys(tests)

  useEffect(() => {
    if (loading || !grades) return

    // When only female students is existing, nothing is showing!
    const _data = chartSummary?.male
    const _femaleData = chartSummary?.female

    const data = grades.data.map(({ grade }) => {
      const datum = _data?.find((d) => d.grade === grade)
      const femaleDatum = _femaleData?.find((d) => d.grade === grade)

      const maleChart = testKeys.map((key) => {
        return {
          subject: t(tests[key].title),
          school: computeTestScore(
            datum?.chart?.[key] ?? [],
            key === 'sprintRun' || key === 'enduranceRun',
          ),
          check: checkTestScore(datum?.chart?.[key] ?? []),
          national: datum?.chart?.[key]?.[1] ?? datum?.chart?.[key]?.[0] ?? 50,
        }
      })

      const femaleChart = testKeys.map((key) => {
        return {
          subject: t(tests[key].title),
          school: computeTestScore(
            femaleDatum?.chart?.[key] ?? [],
            key === 'sprintRun' || key === 'enduranceRun',
          ),
          check: checkTestScore(femaleDatum?.chart?.[key] ?? []),
          national:
            femaleDatum?.chart?.[key]?.[1] ??
            femaleDatum?.chart?.[key]?.[0] ??
            50,
        }
      })

      return {
        grade,
        male: {
          chart: maleChart,
        },
        female: {
          chart: femaleChart,
        },
      }
    })

    setData(data)
  }, [loading, chartSummary, hasEnduranceTest, t, grades])

  if (loading) {
    return <ScreenLoading isLoading={loading} />
  }

  return (
    <div className="py-8 px-4">
      <div className="text-black font-black mb-2">
        {t('Tスコアによる全国平均値による比較')}
      </div>
      {data?.map((data, index: number) => {
        const grade = data.grade

        return (
          <div key={`graph-${index}`}>
            <div className="flex">
              <div className="w-1/2">
                <div className="text-black">
                  {t('個別学年', {
                    count: grade,
                    ordinal: !isUsingJp,
                  })}
                  {t('（男子）')}
                </div>
                <RadarChart data={data.male.chart} height={420} width={520}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <PolarRadiusAxis angle={75} tickCount={10} domain={[0, 60]} />
                  <Radar
                    name={t('学校結果')}
                    dataKey="school"
                    stroke="#FF0000"
                    fill="#FF0000"
                    fillOpacity={0.2}
                    isAnimationActive={false}
                  />
                  <Legend />
                  <Tooltip />
                </RadarChart>
              </div>
              <div className="w-1/2">
                <div className="text-black">
                  {t('個別学年', {
                    count: grade,
                    ordinal: !isUsingJp,
                  })}
                  {t('（女子）')}
                </div>
                <RadarChart data={data.female.chart} height={420} width={520}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <PolarRadiusAxis angle={75} tickCount={10} domain={[0, 60]} />
                  <Radar
                    name={t('学校結果')}
                    dataKey="school"
                    stroke="#FF0000"
                    fill="#FF0000"
                    fillOpacity={0.2}
                    isAnimationActive={false}
                  />
                  <Legend />
                  <Tooltip />
                </RadarChart>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ReportScoreGraphs
