import './RightDropdown.css'

import { CaretDownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Space } from 'antd'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { LOCAL_STORAGE_LANGUAGE_KEY, TLang } from '~/utils/constants'
import { displayFairNav, displayNoneFairNav } from '~/utils/ele'
import isJapanese from '~/utils/isJapanese'

// biome-ignore lint/complexity/noBannedTypes: <explanation>
type RightDropdownProps = {}

/**
 * language switcher.
 */
const RightDropdown: FunctionComponent<RightDropdownProps> = () => {
  const { i18n, t } = useTranslation()
  const isUsingJp = isJapanese(i18n)

  const changeLanguageHandler = (lang: TLang) => {
    i18n.changeLanguage(lang)
    window.localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, lang)
  }

  const menu = (
    <Menu>
      <Menu.Item
        className={isUsingJp ? 'bg-blue-100' : ''}
        key="j"
        onClick={() => {
          if (isUsingJp) return
          changeLanguageHandler('ja')
          displayFairNav()
        }}
      >
        {t('日本語 (Japanese)')}
      </Menu.Item>

      <Menu.Item
        className={!isUsingJp ? 'bg-blue-100' : ''}
        key="e"
        onClick={() => {
          if (!isUsingJp) return
          changeLanguageHandler('en')
          displayNoneFairNav()
        }}
      >
        {t('英語 (English)')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} className="mr-4">
      <Space>
        <div className="text-white text-sm hover:text-blue-100 mb-1">
          {t('言語 (Language)')}
        </div>
        <CaretDownOutlined className="lang-dropdown-icon" />
      </Space>
    </Dropdown>
  )
}

export default RightDropdown
