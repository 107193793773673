import { useMemo } from 'react'
import { ITeacherSchool } from '~/types/api/alpha/teachers'
import { Question } from '~/utils/types'
import PrintingTestResult from './test-result/components/ResultPrinting/PrintingTestResult'
import { StudentTestResult } from './test-result/types/test-result'

const TestResultsPrintPage = ({
  testYear,
  data,
  questionData,
  school,
  hasEnduranceRun,
  questionFilledEmptyCellsLength,
}: {
  testYear: number
  schoolClass: number
  data: StudentTestResult[] | undefined
  questionData: Question[]
  school: ITeacherSchool
  hasEnduranceRun: boolean
  questionFilledEmptyCellsLength: number
}) => {
  const studentListForPrintingMemo = useMemo(() => {
    return (data ?? []).map((student, index) => {
      return (
        <PrintingTestResult
          questionData={questionData}
          school={school}
          testYear={testYear}
          student={student}
          hasEnduranceRun={hasEnduranceRun}
          questionFilledEmptyCellsLength={questionFilledEmptyCellsLength}
          key={index}
        />
      )
    })
  }, [
    data,
    questionData,
    school,
    testYear,
    hasEnduranceRun,
    questionFilledEmptyCellsLength,
  ])

  return <>{studentListForPrintingMemo}</>
}

export default TestResultsPrintPage
