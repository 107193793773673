import { useMemo } from 'react'
import { percentage } from '~/school-utils/cal'

const calTotalA1ToA8 = (payload: { value: number }[]) => {
  let total = 0

  for (const item of payload) {
    total += item.value
  }

  return total
}

const CustomPercentageTooltip = ({
  payload,
  label,
}: {
  /**
   * [
    {
        "fill": "#036EB8",
        "dataKey": "a1",
        "name": "a1",
        "color": "#036EB8",
        "value": 0,
        "payload": {
            "question": "Q10",
            "a1": 0,
            "a2": 1,
            "a3": 0,
            "a4": 0,
            "a5": 0,
            "a6": 0,
            "a7": 0,
            "a8": 0
        }
    },
    ...

    {
        "fill": "#EB99FF",
        "dataKey": "a8",
        "name": "a8",
        "color": "#EB99FF",
        "value": 0,
        "payload": {
            "question": "Q10",
            "a1": 0,
            "a2": 1,
            "a3": 0,
            "a4": 0,
            "a5": 0,
            "a6": 0,
            "a7": 0,
            "a8": 0
        }
    }
]
   */
  payload?: { value: number; dataKey: string; name: string }[]
  label?: string
}) => {
  if (payload?.length) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const total = useMemo(() => calTotalA1ToA8(payload), [payload])

    if (total === 0) {
      return null
    }

    return (
      // recharts need a div for wrapper, that can not be styled
      <div>
        <div className="text-black m-6 rounded-lg bg-gray-50 shadow-lg">
          {/* can not use margin/padding top/bottom wrapper, so created a h-1 element */}
          <p className="h-1" />
          <p className="mx-6 font-bold">{label}</p>

          {payload.map((item) => (
            <p className="mx-6 my-2" key={item.dataKey}>
              <span className="font-bold">
                {item.name.substring(1, item.name.length)}
              </span>

              {`: ${percentage(item.value, total)}%`}
            </p>
          ))}

          {/* can not use margin/padding top/bottom wrapper, so created a h-1 element */}
          <p className="h-1" />
        </div>
      </div>
    )
  }

  return null
}

export default CustomPercentageTooltip
