import { LoginOutlined } from '@ant-design/icons'
import { useApolloClient } from '@apollo/client'
import { Button, message } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { callLogoutApiForSchool } from '~/services/api/logout'
import { Dashboard } from '../layout/Dashboard'

const LogoutPage = () => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const history = useHistory()

  const handleLogoutBtn = useCallback(async () => {
    try {
      await callLogoutApiForSchool()
      await client.clearStore()
      // reload whole page to reset the cache, get latest version of app
      window.location.reload()
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${JSON.stringify(err)}]`)
      console.error('when logout and clearStore, error:', err)
    }
  }, [client, t])

  const handleReturnBtn = useCallback(() => {
    history.push('/home')
  }, [history])

  return (
    <Dashboard selectedMenu={undefined} navbar={t('ログアウト')}>
      <div className="mt-40 text-center">
        <div className="space-y-16">
          <div className="text-xl font-bold">{t('ログアウトしますか？')}</div>
          <LoginOutlined className="text-6xl" />
          <div className="space-x-4">
            <Button
              className="w-60 rounded"
              type="primary"
              onClick={handleLogoutBtn}
            >
              {t('はい')}
            </Button>

            <Button
              className="w-60 rounded"
              type="primary"
              onClick={handleReturnBtn}
            >
              {t('いいえ')}
            </Button>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default LogoutPage
