import { useState } from 'react'
import { read, set_cptable, utils } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import { UploadOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Typography, Upload } from 'antd'

import { Dashboard } from '../layout/Dashboard'

const { Text } = Typography

set_cptable(cp_table)

/**
 * Not in menu.
 */
const ImportQuestionPrefecture = () => {
  const [data, setData] = useState<any[]>([])

  const parseXlsx = async (file) => {
    const _students: {
      id: string | number
      name: string | number
      template: string | number
      questions: (string | number)[]
    }[] = []

    const data = read(await file.arrayBuffer())

    const firstSheetName = data.SheetNames[0]
    const workBook = data.Sheets

    if (firstSheetName && workBook) {
      const rows = utils.sheet_to_json<Record<string, string | number>>(
        workBook[firstSheetName],
      )

      for (const row of rows) {
        const questions: (string | number)[] = []
        if (row['質問番号1']) {
          questions.push(row['質問番号1'])
        }
        for (let x = 2; x <= 20; x++) {
          if (row[x]) {
            questions.push(row[x])
          }
        }
        _students.push({
          id: row['id'],
          name: row['都道府県'],
          template: row['テンプレ作成'],
          questions: questions,
        })
      }
      setData(_students)
    }
  }

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data),
    )}`
    const link = document.createElement('a')
    link.href = jsonString
    link.download = 'questions_prefecture.json'

    link.click()
  }

  return (
    <Dashboard selectedMenu={5} navbar={'個人でできる運動プログラム'}>
      <div className="steps-content flex flex-col items-center justify-center">
        <div className="h-14 border p-3 border-warn">
          <WarningOutlined className="text-3xl warn-icon" />
          <Text type="danger" className="font-black">
            ①でダウンロードしたファイルをアップロードしてください。
          </Text>
        </div>
        <div className="flex mt-6">
          <Upload
            multiple={false}
            name="logo"
            listType="text"
            maxCount={1}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            beforeUpload={(file) => {
              parseXlsx(file)
              return false
            }}
            onRemove={() => {
              setData([])
            }}
          >
            <div className="flex">
              <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                <UploadOutlined className="text-2xl" />
              </div>
              <Button type="primary">生徒情報をアップロード</Button>
            </div>
          </Upload>
        </div>
        <Text className="mb-36">(XLSXファイル式)</Text>
        <Button type="primary" onClick={exportData}>
          Export Data
        </Button>
      </div>
    </Dashboard>
  )
}

export default ImportQuestionPrefecture
