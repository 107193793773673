import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import NextIcon from '../icons/NextIcon'
import { Dashboard } from '../layout/Dashboard'

const UploadExcelPage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Dashboard selectedMenu={3} navbar={''}>
      <div
        className="text-center mt-14"
        style={{ color: 'black', fontSize: 16, fontWeight: 700 }}
      >
        <span>
          {t(
            'エクセルファイルを使って測定結果・アンケート結果の登録を行います。',
          )}
        </span>
      </div>
      <div className="grid-cols-2 grid mt-14 gap-4" style={{ color: 'black' }}>
        <div className="flex justify-end mt-2 cursor-pointer">
          <div
            style={{
              padding: '22px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              minHeight: 240,
            }}
            onClick={() => history.push('/test_result_upload')}
          >
            <div className="flex justify-between">
              <img
                src="shoe_icon.png"
                alt=""
                style={{ width: 80, height: 80, marginBottom: 9 }}
              />
              <NextIcon />
            </div>
            <div className="mb-3">
              <span style={{ fontSize: 18, fontWeight: 700 }}>
                {t('体力テストの結果')}
              </span>
              <div>
                <span style={{ fontSize: 13, fontWeight: 500 }}>
                  {t('体力テストの結果をエクセルを使用して登録する方法です。')}
                </span>
              </div>
            </div>
            <div>
              <img
                src="input-upload.png"
                alt=""
                style={{ width: 373, height: 40 }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-start mt-2 cursor-pointer">
          <div
            style={{
              padding: '22px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              minHeight: 240,
            }}
            onClick={() => history.push('/questionnaire_upload')}
          >
            <div className="flex justify-between">
              <img
                src="print_question.png"
                alt=""
                style={{ width: 80, height: 80, marginBottom: 9 }}
              />
              <NextIcon />
            </div>
            <div className="mb-3">
              <span style={{ fontSize: 18, fontWeight: 700 }}>
                {t('アンケートの結果')}
              </span>
              <div>
                <span style={{ fontSize: 13, fontWeight: 500 }}>
                  {t('アンケートの結果をエクセルを使用して登録する方法です。')}
                </span>
              </div>
            </div>
            <div className="flex justify-center">
              <img
                src="table-upload.png"
                alt=""
                style={{ width: 373, height: 40 }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default UploadExcelPage
