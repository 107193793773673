import { useEffect, useState } from 'react'

/**
 * loopCount = (95% / 5%) = 19
 * tickTime = 200
 * totalLoadingTime = 3800 = tickTime * loopCount
 *
 * So, if totalLoadingTime = 2000, then tickTime ~= 105
 * If tickTime = 20, totalLoadingTime = 19 * 20 = 380
 */
export const useLoadingPercent = (tickTime = 20) => {
  const [loadingPercent, setLoadingPercent] = useState(0)

  useEffect(() => {
    const percentIncrementTimer = window.setTimeout(() => {
      // Max showing percent is 95%, because API do not support loading percent,
      // so we never know when progress is DONE or loading percent is 100%
      if (loadingPercent < 95) {
        setLoadingPercent(loadingPercent + 5)
      }
    }, tickTime)

    if (loadingPercent === 100) {
      // do not need to increment percent when be already 100%
      window.clearTimeout(percentIncrementTimer)
      return
    }

    return () => {
      // remove timer when component unmount
      // do not need to increment percent when component unmount
      clearTimeout(percentIncrementTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPercent])

  return loadingPercent
}
