import { TFunction } from 'i18next'
import { UserGender } from '~/alpha/core'
import { cloneDeep } from '~/school-utils/clone'
import { MaleAvgReport, MaleFemaleOrAllAvgReport } from './type'

export const getDataSrc = (
  schoolReportTestSummary: MaleFemaleOrAllAvgReport | undefined,
  gender: UserGender | undefined,
  t: TFunction<'translation', undefined>,
) => {
  let dataSrc = schoolReportTestSummary
    ? gender === UserGender.Male
      ? schoolReportTestSummary.male
      : gender === UserGender.Female
      ? schoolReportTestSummary.female
      : schoolReportTestSummary.allGender
    : undefined

  if (dataSrc === undefined) {
    return dataSrc
  }

  dataSrc = cloneDeep(dataSrc)
  const totalGrades: MaleAvgReport = {
    rank_a: 0,
    rank_b: 0,
    rank_c: 0,
    rank_d: 0,
    rank_e: 0,

    ranking_count: 0,
    grade: t('学校全体'),
  } as MaleAvgReport

  for (const item of dataSrc) {
    totalGrades.rank_a += item.rank_a
    totalGrades.rank_b += item.rank_b
    totalGrades.rank_c += item.rank_c
    totalGrades.rank_d += item.rank_d
    totalGrades.rank_e += item.rank_e

    // 全種目実施人数
    totalGrades.ranking_count += item.ranking_count
  }

  dataSrc.push(totalGrades)

  return dataSrc
}
