import axios from 'axios'
import { ErrorInfo } from 'react'

export const userAuxInfo = () => {
  const info: { [key: string]: string | number } = {
    location: 'browser',
    url: window.location.href,
    cookie: document.cookie,
    localStorage: JSON.stringify(window.localStorage),
    userAgent: navigator?.userAgent,
    timezone: -new Date(Date.now()).getTimezoneOffset() / 60,
    appName: navigator?.appName,
    product: navigator?.product,
    appVersion: navigator?.appVersion,
    referrer: document.referrer,
    historyLength: window.history?.length,
    browserLang: navigator?.language,
    platform: navigator?.platform,
    scrSizeW: window.screen.width,
    scrSizeH: window.screen.height,
    pixelDepth: window.screen.pixelDepth,
    colorDepth: window.screen.colorDepth,
    scrAvailW: window.screen.availWidth,
    scrAvailH: window.screen.availHeight,
    wWidth: window.innerWidth,
    wHeight: window.innerHeight,
  }
  for (const k of Object.keys(info)) if (!info[k]) delete info[k]
  return info
}

export const sendSlack = async (text: string) => {
  try {
    if (
      process.env.REACT_APP_ENV === 'prod' ||
      process.env.REACT_APP_ENV === 'stg'
    ) {
      await axios.post(
        `${process.env.REACT_APP_REST_API_URL}/report-slack-error`,
        { text },
      )
    }
    // eslint-disable-next-line no-console
    else {
      console.log(`❣ Slack notification: ${text}`)
    }
  } catch {
    // eslint-disable-next-line no-console
    console.error(text)
  }
}

let lastReportTimestamp: Date
export const reportErrorSlack = (e: Error, info?: ErrorInfo) => {
  ;(async () => {
    if (
      /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator?.userAgent)
    )
      return
    if (
      !lastReportTimestamp ||
      Date.now() - lastReportTimestamp.getTime() >= 500
    ) {
      lastReportTimestamp = new Date(Date.now())

      // send to slack
      return sendSlack(
        `App: ${process.env.REACT_APP_NAME}
Text: ${e?.message}
Stack: ${e?.stack}
ErrorInfo: ${info}
Call stack: ${new Error().stack}
User Info: ${JSON.stringify(userAuxInfo(), null, 2)}`,
      )
    }
  })()
}
