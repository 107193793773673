import { TFunction } from 'i18next'
import './summary.css'

import { Row } from 'antd'
import { checkIfIsValidRank } from '~/utils'
import { FitnessTest } from '~/utils/fitnessTest'
import { TTestResultWithPoint } from '~/utils/points'

type Props = {
  testResult: TTestResultWithPoint
  isEnduranceRun: boolean
  t: TFunction<'translation', undefined>
  isUsingJp: boolean
}

const SummaryPointAndRank = ({
  testResult,
  isEnduranceRun,
  t,
  isUsingJp,
}: Props) => {
  const isShowRanking = checkIfIsValidRank(testResult, isEnduranceRun)

  let rank = testResult.rank
  if (!rank && isShowRanking) {
    rank = FitnessTest.computeRank(
      testResult.points || 0,
      Number.isNaN(testResult.age) ? 6 : testResult.age,
    )
  }

  return (
    <>
      <Row
        className={`gap-4 ${isUsingJp ? 'summary-title' : 'summary-title-en'}`}
      >
        <div className="text-center font-black border-b-2 border-primary h-6 summary-item">
          {t('総合得点')}
        </div>

        <div className="text-center font-black border-b-2 border-primary h-6 summary-item">
          {t('総合評価')}
        </div>
      </Row>

      <Row className="gap-4 text-2xl text-center font-black text-black summary-point">
        <div className="summary-val-container flex justify-center items-center summary-item">
          {testResult.points ?? '-'}
        </div>

        <div className="summary-val-container flex justify-center items-center summary-item">
          {isShowRanking ? rank ?? '-' : '-'}
        </div>
      </Row>
    </>
  )
}

export default SummaryPointAndRank
