import create from 'zustand'
import { persist } from 'zustand/middleware'
import { LOCAL_STORAGE_SCHOOL_USER_KEY } from '~/school-utils/constants'
import { getObjFromLocalStorage } from '~/school-utils/storages'
import type { ITeacher, ITeacherSchool } from '~/types/api/alpha/teachers'

interface ISchoolStore {
  teacher?: ITeacher | null
  school?: ITeacherSchool | null
  schoolCategoryCode?: string | null
  setTeacher: (me: ITeacher) => void
  setSchool: (school: ITeacherSchool) => void
}

const useAlphaStore = create<ISchoolStore>()(
  persist(
    (set) => ({
      teacher:
        getObjFromLocalStorage<{
          state: { teacher: ITeacher }
          version: number
        }>(LOCAL_STORAGE_SCHOOL_USER_KEY)?.state.teacher ?? null,

      school: null,
      schoolCategoryCode: null,
      setTeacher: (teacher: ITeacher) => set({ teacher }),

      setSchool: (school: ITeacherSchool) =>
        set({
          school,
          schoolCategoryCode: school?.attributes?.schoolCategoryCode,
        }),
    }),
    {
      partialize: (state) => ({
        teacher: state.teacher,
      }),
      name: LOCAL_STORAGE_SCHOOL_USER_KEY,
    },
  ),
)

export { useAlphaStore }
