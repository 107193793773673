type TickProps = {
  payload: { value: string }
  x: number
  y: number
  textAnchor: string | undefined
  stroke: string | undefined
  radius: string | number | undefined
}

const Tick = ({ payload, x, y, textAnchor, stroke, radius }: TickProps) => {
  return (
    <g className="recharts-layer recharts-polar-angle-axis-tick">
      <text
        radius={radius}
        stroke={stroke}
        x={x}
        y={y}
        className="recharts-text recharts-polar-angle-axis-tick-value"
        text-anchor={textAnchor}
      >
        <tspan x={x} dy={payload.value === 'Grip Strength' ? '-0.5em' : '0em'}>
          {payload.value}
        </tspan>
        {/* Depend on translated word in English */}
        {payload.value === 'Standing' && (
          <tspan x={x + 8} dy="1em">
            Long Jump
          </tspan>
        )}
      </text>
    </g>
  )
}

export default Tick
