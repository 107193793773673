import { Progress } from 'antd'

type Props = {
  percent: number
  isError?: boolean
}

const CircularProgressLoading = ({ percent, isError }: Props) => {
  return (
    <Progress
      type="circle"
      width={88}
      percent={percent}
      status={isError ? 'exception' : undefined}
    />
  )
}

export default CircularProgressLoading
