import { useGradesLazyQuery } from '@alpha/core'
import { Col, Row, Select, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnType } from 'antd/es/table'
import _ from 'lodash'
import { useAlphaStore } from '~/context'
import Api from '~/utils/api'
import { currentYear, pageSize as defaultPageSize } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { Dashboard } from '../layout/Dashboard'
import { DateForm } from '../layout/DateForm'
import { incorrectValue } from '../shared/IncorrectValue'

const { Option } = Select

// WARN: When using English, given names and family names order is reversed,
// so the family name (Last Name) is saved in the given name (First Name) in DB.

/**
 * /date_of_birth
 */
const DateOfBirthPage = () => {
  const { school } = useAlphaStore()
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  const [listGrades, setListGrades] = useState<number[]>([])
  const [listClasses, setListClasses] = useState<number[]>([])
  const [schoolGrade, setSchoolGrade] = useState(0)
  const [schoolClass, setSchoolClass] = useState(0)
  const [keySort, setKeySort] = useState('')
  const [valueSort, setValueSort] = useState('1')
  const [data, setData] = useState<any[]>()
  const [filterGender, setFilterGender] = useState('')
  const [reloading, setReloading] = useState(true)
  const [pagination, setPagination] = useState({
    total: 0,
    maxPage: 0,
    currentPage: 0,
    pageSize: 0,
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const getStudentData = async () => {
    setReloading(true)
    const dataParams: string[] = []

    dataParams.push(`testYear=${currentYear}`)
    dataParams.push(`currentPage=${currentPage}`)
    dataParams.push(`pageSize=${pageSize}`)
    dataParams.push(`schoolGrade=${schoolGrade}`)
    dataParams.push(`schoolClass=${schoolClass}`)
    dataParams.push(`gender=${filterGender}`)
    dataParams.push(`sorting=${keySort}`)
    dataParams.push(`ordering=${valueSort}`)

    const apiUrl = `/alpha/v1/school/listStudents?${dataParams.join('&')}`

    const { data } = await Api.get(apiUrl)
    const clonedData = _.cloneDeep(data.data.studentData)
    const clonedPagination = _.cloneDeep(data.data.paginationOptions)
    const dataClone: any[] = []
    if (clonedData.length) {
      clonedData.forEach((value) => {
        dataClone.push({ ...value, _id: value.attributes?._id })
      })
    }
    setData(dataClone)
    setPagination(clonedPagination)
    setReloading(false)
  }

  const [getGrades] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getStudentData()
  }, [
    currentPage,
    pageSize,
    schoolClass,
    schoolGrade,
    filterGender,
    keySort,
    valueSort,
  ])

  // getGrades
  useEffect(() => {
    if (!school) return

    getGrades({
      variables: {
        input: {
          schoolId: school._id,
          testYear: currentYear,
        },
      },
    })
  }, [school, getGrades])

  const sortTests = (element1, value, key) => {
    setKeySort(key)
    setValueSort(value === 'ascend' ? '1' : '-1')
    return element1
  }

  const tableColumns: ColumnType<any>[] = [
    {
      title: t('学年'),
      dataIndex: ['attributes', 'schoolGrade'],
      key: 'schoolGrade',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'schoolGrade')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('組'),
      dataIndex: ['attributes', 'schoolClass'],
      key: 'schoolClass',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'schoolClass')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('番'),
      dataIndex: ['attributes', 'schoolAttendanceNumber'],
      key: 'schoolAttendanceNumber',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'schoolAttendanceNumber')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('性別'),
      dataIndex: ['attributes', 'gender'],
      key: 'gender',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'gender')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => <div>{v ? (v === 'MALE' ? t('男') : t('女')) : '-'}</div>,
    },
    {
      title: t('姓'),
      dataIndex: ['attributes', 'familyName'],
      key: 'familyName',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'familyName')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return <div>{v}</div>
      },
    },
    {
      title: t('名'),
      dataIndex: ['attributes', 'givenName'],
      key: 'givenName',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'givenName')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return <div>{v}</div>
      },
    },
    isUsingJp
      ? {
          title: t('せい'),
          dataIndex: ['attributes', 'familyNameHiragana'],
          key: 'familyNameHiragana',
          className: 'text-center-f whitespace-nowrap',
          sorter: (a, b, key) => {
            return sortTests(a, key, 'familyNameHiragana')
          },
          sortDirections: ['descend', 'ascend'],
          render: (v) => {
            return <div>{v}</div>
          },
        }
      : false,
    isUsingJp
      ? {
          title: t('めい'),
          dataIndex: ['attributes', 'givenNameHiragana'],
          key: 'givenNameHiragana',
          className: 'text-center-f whitespace-nowrap',
          sorter: (a, b, key) => {
            return sortTests(a, key, 'givenNameHiragana')
          },
          sortDirections: ['descend', 'ascend'],
          render: (v) => {
            return <div>{v}</div>
          },
        }
      : false,
    {
      title: t('生年月日'),
      dataIndex: ['attributes'],
      className: 'text-center-f whitespace-nowrap flex',
      key: 'actions',
      render: (v, r) => {
        return <DateForm dataBirth={v} testYear={currentYear} />
      },
    },
  ].filter(Boolean) as ColumnType<any>[]

  const getFilterData = async () => {
    const apiUrl = `/alpha/v1/school/filterStudents?testYear=${currentYear}`

    const { data: dataFilter } = await Api.get(apiUrl)
    setListGrades(dataFilter?.data?.grades)
    setSchoolGrade(
      dataFilter?.data?.grades?.length ? dataFilter?.data?.grades[0] : 0,
    )
    setListClasses(dataFilter?.data?.classes)
    setSchoolClass(
      dataFilter?.data?.classes?.length ? dataFilter?.data?.classes[0] : 0,
    )
  }

  useEffect(() => {
    getFilterData().then()
  }, [])

  return (
    <Dashboard selectedMenu={3} navbar={t('生年月日')}>
      {
        <Row className="w-full justify-center form-date-of-birth">
          <Col className="mt-16" xxl={{ span: 20 }} lg={{ span: 22 }}>
            <div className="space-x-10 pb-2 print:hidden flex">
              <div className="flex items-center">
                <span className="mr-2">{t('学年')}</span>
                <Select
                  className="w-30 rounded-5px"
                  value={schoolGrade}
                  onChange={setSchoolGrade}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key={'grade-default'} value={0}>
                    {t('全学年')}
                  </Option>

                  {listGrades.map((v) => (
                    <Option value={v} key={v}>
                      {t('個別学年', {
                        count: v,
                        ordinal: !isUsingJp,
                      })}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="flex items-center ml-10">
                <span className="mr-2">{t('組')}</span>
                <Select
                  className="w-30 rounded-5px"
                  value={schoolClass}
                  onChange={setSchoolClass}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key={'class-default'} value={0}>
                    {t('全組')}
                  </Option>

                  {listClasses.map((_class) => {
                    return (
                      <Option key={`class-${_class}`} value={_class}>
                        {t('個別組', {
                          count: _class,
                          ordinal: !isUsingJp,
                        })}
                      </Option>
                    )
                  })}
                </Select>
              </div>
              <div className="flex items-center ml-10">
                <span className="mr-2">{t('性別')}</span>
                <Select
                  className="w-30 rounded-5px"
                  value={filterGender}
                  onChange={(g) => setFilterGender(g)}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="gender-0" value="">
                    {t('全性別')}
                  </Option>
                  <Option key="gender-male" value="MALE">
                    {t('男性')}
                  </Option>
                  <Option key="gender-female" value="FEMALE">
                    {t('女性')}
                  </Option>
                </Select>
              </div>
            </div>
            <div className="flex justify-center mt-8">
              <Table
                columns={tableColumns}
                dataSource={data}
                loading={reloading}
                rowKey="_id"
                size="small"
                style={{ minWidth: 940 }}
                pagination={{
                  pageSize: pageSize,
                  defaultPageSize: pageSize,
                  position: ['bottomCenter'],
                  showSizeChanger: true,
                  total: pagination?.total,
                  current: currentPage,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page)
                    setPageSize(pageSize)
                  },
                }}
                bordered={true}
              />
            </div>
          </Col>
        </Row>
      }
    </Dashboard>
  )
}

export default DateOfBirthPage
