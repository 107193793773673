import { useTranslation } from 'react-i18next'
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
} from 'recharts'
import isJapanese from '~/utils/isJapanese'
import { ScoreData } from './ScoreRadarChart'
import Tick from './shared/Tick'

export type ContainerSize = {
  height: number | string
  width: number | string
}

type ChartSize = {
  height: number
  width: number
}

type Props = {
  data: ScoreData[]
  outerRadius?: number
  size?: ContainerSize
  radarChartSize?: ChartSize
}

const ScoreRadarChartPrinting = ({
  data,
  outerRadius = 68,
  radarChartSize = {
    width: 480,
    height: 288,
  },
}: Props) => {
  const { t, i18n } = useTranslation()
  const isUsingJp = isJapanese(i18n)

  const radarChart = (
    <RadarChart
      width={radarChartSize.width}
      height={radarChartSize.height}
      data={data}
      outerRadius={outerRadius}
    >
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" tick={isUsingJp ? true : Tick} />
      <PolarRadiusAxis
        angle={75}
        tickCount={10}
        domain={[0, 10]}
        style={{ fontSize: '12px' }}
      />

      <Radar
        name={t('thisYear')}
        dataKey="personal"
        stroke="#FF0000"
        fill="#FF0000"
        fillOpacity={0.2}
        isAnimationActive={false}
      />

      <Radar
        name={t('昨年の個人結果')}
        dataKey="prevPersonal"
        stroke="#0000FF"
        fill="#0000FF"
        fillOpacity={0.2}
        isAnimationActive={false}
      />
      <Legend />
    </RadarChart>
  )

  return <div className="flex justify-center">{radarChart}</div>
}

export default ScoreRadarChartPrinting
