import axios from 'axios'
import { AUTH_TOKEN_KEY, LOCAL_STORAGE_AUTH_TOKEN_KEY } from '~/alpha/core'
import { LOCAL_STORAGE_POPUP } from '~/utils/constants'
import {
  LOCAL_STORAGE_EMAIL_PHONE_KEY,
  LOCAL_STORAGE_SCHOOL_USER_KEY,
} from './constants'

export const getObjFromLocalStorage = <T = unknown>(
  key:
    | typeof LOCAL_STORAGE_SCHOOL_USER_KEY
    | typeof LOCAL_STORAGE_EMAIL_PHONE_KEY
    | typeof LOCAL_STORAGE_POPUP,
): null | T => {
  try {
    return JSON.parse(window.localStorage.getItem(key) as string) as null | T
  } catch (err) {
    console.error(`getObjFromLocalStorage - ${key}`, err)

    return null
  }
}

const clearedStorageKeys = [
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
  LOCAL_STORAGE_SCHOOL_USER_KEY,
  LOCAL_STORAGE_EMAIL_PHONE_KEY,
  /**
   * {@link https://github.com/carvajalconsultants/apollo-link-offline}
   */
  'offlineLinkAttemptIds',
] as const

export const clearLocalStorage = () => {
  for (const key of clearedStorageKeys) {
    window.localStorage.removeItem(key)
  }
}

export const clearStorageAndAuthToken = () => {
  clearLocalStorage()
  delete axios.defaults.headers.common[AUTH_TOKEN_KEY]
}
