export const MenuIcon = (
  <svg
    className="w-8 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 2H32" stroke="white" strokeWidth="3" strokeLinecap="round" />
    <path
      d="M2 11.375H24.5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M2 20.75H17"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)
