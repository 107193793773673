import { useMemo } from 'react'
import { ITeacherSchool } from '~/types/api/alpha/teachers'
import { TStudent } from '../../../../utils/types/api/res'
import AwardPerStudentPrinting from './AwardsPrinting/AwardPerStudentPrinting'

const AwardsPrinting = ({
  testYear,
  data,
  school,
  hasEnduranceRun,
}: {
  testYear: number
  schoolClass: number
  data: TStudent[] | undefined
  school: ITeacherSchool
  hasEnduranceRun: boolean
}) => {
  const studentListForPrintingMemo = useMemo(() => {
    if (!data) return

    return data.map((student) => {
      return (
        <AwardPerStudentPrinting
          school={school}
          testYear={testYear}
          student={student}
          hasEnduranceRun={hasEnduranceRun}
          key={student._id}
        />
      )
    })
  }, [data, school, testYear, hasEnduranceRun])

  return <>{studentListForPrintingMemo}</>
}

export default AwardsPrinting
