import './OverallRankingTable.css'

import { Col, Row, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { TColumn } from '../types'
import { rowDefaultClassName } from '../utils'
import { defPagination } from './utils/const'

const countColumnsClassName = 'w-20 h-6 font-black ant-table-all-test'
const countColumnsTitleClassName = 'w-20 h-6 font-black ant-table-all-test'
const overallColumnsClassName =
  'w-20 h-6 text-center-f font-black ant-table-all-test'
const tableTitleClassName =
  'h-6 mb-2 flex justify-center items-center text-xs text-white font-black table-all-test'

interface OverallRankingInfo {
  count: number
  ranking_count: number
  a_b: number
  d_e: number
  a_b_d_e: number
  testYear: number
}

interface ICustomOverallRankingInfo {
  count?: number
  ranking_count?: number
  a_b?: number
  d_e?: number
  a_b_d_e?: number
  testYear?: number
  grade: string
}

interface OverallRankingTablePro {
  /**
   * @example
   * {
    "allGender": {
        "count": 162,
        "ranking_count": 4,
        "a_b": 2,
        "d_e": 2,
        "a_b_d_e": 0,
        "testYear": 2024
    },
    "female": {
        "count": 82,
        "ranking_count": 3,
        "a_b": 2,
        "d_e": 1,
        "a_b_d_e": 1,
        "testYear": 2024,
        "gender": "FEMALE"
    },
    "male": {
        "count": 80,
        "ranking_count": 1,
        "a_b": 0,
        "d_e": 1,
        "a_b_d_e": -1,
        "testYear": 2024,
        "gender": "MALE"
    }
  }
   */
  schoolReportTestSummary:
    | {
        male: OverallRankingInfo
        female: OverallRankingInfo
        allGender: OverallRankingInfo
      }
    | undefined

  loading: boolean
  isUsingJp: boolean
}

const getRowClassName = (_: unknown, idx: number) => {
  return `${rowDefaultClassName} ${
    idx % 2 === 0 ? 'ant-table-row-black-dark' : 'ant-table-row-black-light'
  }`
}

const OverallRankingTable = ({
  schoolReportTestSummary,
  loading,
  isUsingJp,
}: OverallRankingTablePro) => {
  const { t } = useTranslation()

  const dataShow: ICustomOverallRankingInfo[] = []

  dataShow.push({ ...schoolReportTestSummary?.male, grade: t('男子') })
  dataShow.push({ ...schoolReportTestSummary?.female, grade: t('女子') })
  dataShow.push({ ...schoolReportTestSummary?.allGender, grade: t('全体') })

  const countColumnsTitleClassNameForLang = `${countColumnsTitleClassName}${
    isUsingJp ? '' : ' all-test-en'
  }`

  const countColumns: TColumn[] = [
    {
      title: t('全種目実施人数'),
      dataIndex: 'ranking_count',
      key: 'ranking_count',
      className: countColumnsTitleClassNameForLang,
    },
    {
      title: 'A＋B',
      dataIndex: 'a_b',
      key: 'a_b',
      className: countColumnsTitleClassNameForLang,
    },
    {
      title: 'D＋E',
      dataIndex: 'd_e',
      key: 'd_e',
      className: countColumnsTitleClassNameForLang,
    },
    {
      title: '（A＋B）ー（D＋E）',
      dataIndex: 'a_b_d_e',
      key: 'a_b_d_e',
      className: countColumnsTitleClassNameForLang,
    },
  ]

  const countColumnsClassNameForLang = `${countColumnsClassName}${
    isUsingJp ? '' : ' all-test-en'
  }`

  const ratioColumns: TColumn[] = [
    {
      title: t('実施率'),
      dataIndex: 'count',
      key: 'ranking_count',
      className: countColumnsClassNameForLang,
      render: (v, r) => {
        if (!v || !r.ranking_count) return '0%'
        return `${((r.ranking_count / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'A＋B',
      dataIndex: 'ranking_count',
      key: 'a_b',
      className: countColumnsClassNameForLang,
      render: (v, r) => {
        if (!v || !r.a_b) return '0%'
        return `${((r.a_b / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'D＋E',
      dataIndex: 'ranking_count',
      key: 'd_e',
      className: countColumnsClassNameForLang,
      render: (v, r) => {
        if (!v || !r.d_e) return '0%'
        return `${((r.d_e / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: '（A＋B）ー（D＋E）',
      dataIndex: 'ranking_count',
      key: 'a_b_d_e',
      className: countColumnsClassNameForLang,
      render: (v, r) => {
        if (!v || !r.a_b_d_e) return '0%'
        return `${((r.a_b_d_e / v) * 100).toFixed(2)}%`
      },
    },
  ]

  const overallColumnsLangClassName = `${overallColumnsClassName}${
    isUsingJp ? '' : ' all-test-en'
  }`

  const overallColumns: TColumn[] = [
    {
      title: '',
      dataIndex: 'grade',
      key: 'grade',
      className: overallColumnsLangClassName,
    },
    {
      title: t('人数'),
      dataIndex: 'count',
      key: 'count',
      className: overallColumnsLangClassName,
    },
  ]

  return (
    <Row gutter={4}>
      <Col span={4}>
        <div className={tableTitleClassName}>{t('学校全体')}</div>

        <Table
          columns={overallColumns}
          dataSource={dataShow}
          style={{ width: 200 }}
          rowKey="grade"
          size="small"
          rowClassName={getRowClassName}
          pagination={defPagination}
          loading={loading}
        />
      </Col>

      <Col span={10}>
        <div className={tableTitleClassName}>{t('人数')}</div>

        <Table
          columns={countColumns}
          dataSource={dataShow}
          style={{ width: 500 }}
          rowKey="grade"
          size="small"
          rowClassName={getRowClassName}
          pagination={defPagination}
          loading={loading}
        />
      </Col>

      <Col span={10}>
        <div className={tableTitleClassName}>{t('割合')}</div>

        <Table
          columns={ratioColumns}
          dataSource={dataShow}
          style={{ width: 500 }}
          rowKey="grade"
          size="small"
          rowClassName={getRowClassName}
          pagination={defPagination}
          loading={loading}
        />
      </Col>
    </Row>
  )
}

export default OverallRankingTable
