import axios from 'axios'
import { ProcessingUploadQuestionnaireDataRequestStudent } from '~/types/api/alpha/questions'

export const updateSurvey = async (
  students: ProcessingUploadQuestionnaireDataRequestStudent[],
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/upload-survey`,
    {
      students: JSON.stringify(students),
    },
  )

  return res
}
