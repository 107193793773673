import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { UserGender } from '~/alpha/core'
import { TColumn } from '../types'

const ClubsReportTable = ({
  questionExtra,
  extraActivitySummary,
  gender,
  loading,
  showPage,
  hasEnduranceTest,
}) => {
  const { t } = useTranslation()

  const isMale = gender === UserGender.Male
  const genderClass = isMale ? 'male' : 'female'
  const rowColor = isMale ? 'blue' : 'pink'

  const testColumns: TColumn[] = [
    {
      title: t('部活名'),
      dataIndex: ['_id', 'q9'],
      key: 'club_name',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => {
        return questionExtra?.[v]?.name
      },
    },
    {
      title: t('人数'),
      dataIndex: 'count',
      key: 'count',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: t('身長'),
      dataIndex: 'height_avg',
      key: 'height_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: t('体重'),
      dataIndex: 'weight_avg',
      key: 'weight_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: t('握力'),
      dataIndex: 'grip_avg',
      key: 'grip_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: t('上体起こし'),
      dataIndex: 'sitUps_avg',
      key: 'sitUps_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: t('長座体前屈'),
      dataIndex: 'bending_avg',
      key: 'bending_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: t('反復横跳び'),
      dataIndex: 'sideJump_avg',
      key: 'sideJump_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: t('シャトルラン'),
      dataIndex: 'shuttleRun_avg',
      key: 'shuttleRun_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    ...(hasEnduranceTest
      ? [
          {
            title: t('持久走'),
            dataIndex: 'enduranceRun_avg',
            key: 'enduranceRun_avg',
            className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
            render: (v, _) => v?.toFixed(2) ?? '0.0',
          },
        ]
      : []),
    {
      title: t('50m走'),
      dataIndex: 'sprintRun_avg',
      key: 'sprintRun_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: t('立ち幅跳び'),
      dataIndex: 'standingJump_avg',
      key: 'standingJump_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: t('ボール投げ'),
      dataIndex: 'handballThrow_avg',
      key: 'handballThrow_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: t('得点合計'),
      dataIndex: 'points_avg',
      key: 'points_avg',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
  ]

  return (
    <>
      <div className="space-y-2">
        <div
          className={`h-6 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {t('部活動')}
          {t(isMale ? '（男子）' : '（女子）')}
        </div>
        {showPage === 'print' ? (
          <Table
            columns={testColumns}
            dataSource={
              isMale ? extraActivitySummary?.male : extraActivitySummary?.female
            }
            style={{ width: 1185 }}
            rowKey="grade"
            size="small"
            rowClassName={(_, idx) => {
              const _default = 'text-10px text-black font-bold'
              return `${_default} ${
                idx % 2 !== 0
                  ? `ant-table-row-${rowColor}-light`
                  : `ant-table-row-${rowColor}-dark`
              }`
            }}
            loading={loading}
            pagination={false}
          />
        ) : (
          <Table
            columns={testColumns}
            dataSource={
              isMale ? extraActivitySummary?.male : extraActivitySummary?.female
            }
            style={{ width: 1185 }}
            rowKey="grade"
            size="small"
            rowClassName={(_, idx) => {
              const _default = 'text-10px text-black font-bold'
              return `${_default} ${
                idx % 2 !== 0
                  ? `ant-table-row-${rowColor}-light`
                  : `ant-table-row-${rowColor}-dark`
              }`
            }}
            loading={loading}
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 10,
            }}
          />
        )}
      </div>
    </>
  )
}

export default ClubsReportTable
