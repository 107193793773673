import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useAlphaStore } from '~/context'
import NextIcon from '../icons/NextIcon'
import { Dashboard } from '../layout/Dashboard'

/**
 * Path: /result
 */
const ResultPage = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  return (
    <Dashboard selectedMenu={4} navbar={t('結果')}>
      <div className="grid-cols-2 grid mt-14 gap-4" style={{ color: 'black' }}>
        <div className="flex justify-end cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/test_result')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('体力テスト一覧')}
              </span>
              <NextIcon />
            </div>
            <div>
              <span>
                {t(
                  '全生徒の体カテスト結果一覧を確認することができます。また、個人結果表の印刷及び体力テスト結果のダウンロードができます。',
                  { student: isElementarySchool ? '児童' : '生徒' },
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-start cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/test_report')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('集計結果')}
              </span>
              <NextIcon />
            </div>
            <div>
              <span>{t('各種集計結果の確認・印刷ができます。')}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-2 cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/questionnaire_result')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('アンケート一覧')}
              </span>
              <NextIcon />
            </div>
            <div>
              <span>
                {t(
                  '全生徒のアンケート結果一覧を確認することができます。また、アンケート結果のダウンロードができます。',
                  { student: isElementarySchool ? '児童' : '生徒' },
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default ResultPage
