import create from 'zustand'
import { persist } from 'zustand/middleware'
import { getObjFromLocalStorage } from '~/school-utils/storages'
import { LOCAL_STORAGE_POPUP } from '~/utils/constants'

export interface IPopupStoreInfo {
  popups: Array<{
    _id: string
    content: string
    createdAt: Date
    readAt?: Date
  }>
  readIds: string[]
}

interface IPopupStore extends IPopupStoreInfo {
  setPopups: (
    popups: Array<{
      _id: string
      content: string
      createdAt: Date
      readAt?: Date
    }>,
  ) => void
  setReadIds: (readIds: string[]) => void
}

const initValue = getObjFromLocalStorage<{
  state: IPopupStoreInfo
  version: number
}>(LOCAL_STORAGE_POPUP)?.state

export const usePopupStore = create<IPopupStore>()(
  persist(
    (set) => ({
      popups: initValue?.popups ?? [],
      readIds: initValue?.readIds ?? [],

      setPopups: (popups) => set({ popups }),
      setReadIds: (readIds: string[]) => set({ readIds }),
    }),
    {
      name: LOCAL_STORAGE_POPUP,
    },
  ),
)
