import { convertJapNumbers } from '.'
import { TUserGender } from './constants'

export const getValidPositiveNumberFromExcelFile = (
  value: number | string | undefined | null,
): false | number => {
  if (!value) {
    return false
  }

  const numVal =
    typeof value === 'number'
      ? value
      : Number.parseInt(convertJapNumbers(value))

  if (Number.isNaN(numVal) || numVal <= 0) {
    return false
  }

  return numVal
}

export const getValidGenderFromJpText = (
  inputVal: number | string | undefined | null,
): false | TUserGender => {
  if (inputVal === '男' || inputVal === 'male' || inputVal === 'Male') {
    return 'MALE'
  }

  if (inputVal === '女' || inputVal === 'female' || inputVal === 'Female') {
    return 'FEMALE'
  }

  return false
}

/**
 * Parse display value to validate number.
 *
 * @param displayValue
 * @returns
 */
export const parseDisplayValueToValidateNumber = (
  displayValue: string | undefined,
) => {
  return convertJapNumbers(displayValue as string)
}
