export const computeTestScore = (
  inputs: number[],
  reverse: boolean,
): string => {
  const [a, b = a, c = 1] = inputs

  if (a === null || a === undefined) return '0'
  let ret = 50
  if (a) {
    ret = reverse ? 50 - ((a - b) / c) * 10 : ((a - b) / c) * 10 + 50
  }

  return ret.toFixed(2)
}

export const checkTestScore = (inputs: number[]): boolean => {
  const [a] = inputs
  let ret = false
  if (a) {
    ret = true
  }

  return ret
}

/**
 * Translated at component.
 */
export const showTextDetail = {
  1: '学年平均値及び標準偏差',
  2: '総合評価',
  3: 'アンケート',
  4: 'クラス平均値',
  5: '部活動平均値',
  6: '全国との比較',
} as const

export const rowDefaultClassName = 'text-10px text-black font-bold'
