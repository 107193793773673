import create from 'zustand'
import { persist } from 'zustand/middleware'
import { LOCAL_STORAGE_EMAIL_PHONE_KEY } from '~/school-utils/constants'
import { getObjFromLocalStorage } from '~/school-utils/storages'

export interface ISchoolRequiredInputInfo {
  phone: string | null | undefined
  email: string | null | undefined
}

interface ISchoolRequiredStore extends ISchoolRequiredInputInfo {
  setPhone: (phone: string | null | undefined) => void
  setEmail: (email: string | null | undefined) => void
}

const initValue = getObjFromLocalStorage<{
  state: ISchoolRequiredInputInfo
  version: number
}>(LOCAL_STORAGE_EMAIL_PHONE_KEY)?.state

export const useEmailPhoneStore = create<ISchoolRequiredStore>()(
  persist(
    (set) => ({
      phone: initValue?.phone,
      email: initValue?.email,

      setPhone: (phone: string | null | undefined) => set({ phone }),
      setEmail: (email: string | null | undefined) => set({ email }),
    }),
    {
      name: LOCAL_STORAGE_EMAIL_PHONE_KEY,
    },
  ),
)
