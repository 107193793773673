import { message } from 'antd'
import { TFunction } from 'i18next'
import { WorkBook, read, set_cptable, utils, writeFile } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'
import { SchoolInvitationCode } from '~/alpha/core'
import { getFullStudentInvitationCode } from './code'

set_cptable(cp_table)

/**
 *
 * @param grade 学年
 * @param schoolClass 組
 * @param students
 * @param schoolInvitationCode
 */
export const downloadInvitationCodeExcelFile = (
  grade: number,
  schoolClass: number,
  students: SchoolInvitationCode['students'] | undefined,
  schoolInvitationCode: string,
  t: TFunction<'translation', undefined>,
) => {
  const workbook = utils.book_new()

  const columnTitles = [
    t('学年'),
    t('組'),
    t('出席番号'),
    t('姓'),
    t('名'),
    t('サインインコード'),
  ]

  const studentRows: (string | number)[][] = [columnTitles]

  // @ts-ignore
  for (const student of students) {
    const row = [
      grade,
      schoolClass,
      // @ts-ignore
      student.attendanceNumber,
      // @ts-ignore
      student.familyName,
      // @ts-ignore
      student.givenName,
      getFullStudentInvitationCode(
        schoolInvitationCode,
        // @ts-ignore
        student.attendanceNumber,
        // @ts-ignore
        student.studentInvitationCode,
      ),
    ]

    // @ts-ignore
    studentRows.push(row)
  }

  const worksheet = utils.json_to_sheet(studentRows, { skipHeader: true })

  // utils.sheet_add_aoa(worksheet, [columnTitles, [22, 11, 12]])
  // utils.sheet_add_json(worksheet, [], { origin: 'A2' })
  utils.book_append_sheet(workbook, worksheet, t('生徒登録'))

  const fileName = `ALPHA${t('サインインコード一覧')}${t('（')}${grade}${t(
    'grade年',
  )}${schoolClass}${t('組')}${t('）')}.xlsx`

  writeFile(workbook, fileName, {
    bookType: 'xlsx',
  })
}

export const readWorkbookFromPublic = async (
  t: TFunction<'translation', undefined>,
  fileName: string,
): Promise<undefined | WorkBook> => {
  let response: Response
  try {
    // Fetch the XLSX file from the public folder
    response = await fetch(`/${fileName}.xlsx`)
    const blob = await response.blob()
    // Read the file as an ArrayBuffer
    const arrayBuffer = await blob.arrayBuffer()

    // Read the workbook from the ArrayBuffer
    const workbook = read(arrayBuffer, { type: 'array' })

    return workbook
  } catch (error) {
    message.error(
      `${t('エラーが発生しました。')} Error downloading the template file: [${
        (error as Error).message
      }]`,
    )

    console.error('Error downloading the template file:', error)
    return undefined
  }
}
