import { TEachTestRes } from '~/utils/points'

export const getShowingTestResult = (result: TEachTestRes | undefined) => {
  const val = result?.value

  const resultValue: string | number =
    val !== undefined && val !== null && (val as unknown as string) !== ''
      ? val
      : '-'

  return result?.points && result.points > 0 && !result.isNotMeasurable
    ? resultValue
    : '-'
}
