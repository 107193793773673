import { CloseOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import _ from 'lodash'
import { LegacyRef, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { useTranslation } from 'react-i18next'
import type { MetaStudent, SchoolInvitationCode } from '~/alpha/core'
import type { SchoolYearInfoTable } from '../pages/school-year-info/types'
import { PrintingFor1 } from './qr-code/PrintingFor1'
import { PrintingFor9 } from './qr-code/PrintingFor9'
import { QrList } from './qr-code/QrList'

export const QRCodeModal = ({
  data,
  showModal,
  setShowModal,
}: {
  data: {
    code: SchoolInvitationCode
    data: SchoolYearInfoTable[]
  }
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()

  const studentArr = (data?.code.students || []) as MetaStudent[]

  const handleCancel = () => {
    setShowModal(false)
  }

  // QR_modal
  const QRmodalProps = {
    bodyStyle: { background: '#E9ECF6', borderRadius: '10px' },
    width: '1200px',
    visible: showModal,
    onCancel: handleCancel,
    closable: false,
    closeIcon: <CloseOutlined className="text-base color-blue" />,
    footer: null,
  }

  const nineModalRef = useRef<HTMLDivElement>()
  const oneModalRef = useRef<HTMLDivElement>()

  const handleNinePrint = useReactToPrint({
    content: () => nineModalRef.current ?? null,
  })

  const handleOnePrint = useReactToPrint({
    content: () => oneModalRef.current ?? null,
  })

  return (
    <div>
      {data && (
        <Modal {...QRmodalProps}>
          <QrList
            students={studentArr}
            schoolGrade={data.code.schoolGrade}
            schoolClass={data.code.schoolClass}
            code={data.code.code}
            handleCancel={handleCancel}
          />

          <div className="w-full flex justify-center">
            <Button
              className="w-60 rounded mx-4"
              type="primary"
              onClick={handleNinePrint}
            >
              {t('印刷（1枚につき9人の情報）')}
            </Button>

            <Button
              className="w-60 rounded mx-4"
              type="primary"
              onClick={handleOnePrint}
            >
              {t('印刷（1枚につき1人の情報）')}
            </Button>
          </div>

          <div
            ref={nineModalRef as LegacyRef<HTMLDivElement>}
            className="print-container m-0 p-0"
          >
            {_.chunk(studentArr, 3).map((chunk, id) => (
              <PrintingFor9
                key={id}
                students={chunk}
                schoolGrade={data.code.schoolGrade}
                schoolClass={data.code.schoolClass}
                code={data.code.code}
              />
            ))}
          </div>

          <div
            ref={oneModalRef as LegacyRef<HTMLDivElement>}
            className="print-container m-0 p-0"
          >
            {studentArr.map(
              (
                {
                  attendanceNumber,
                  familyName,
                  givenName,
                  studentInvitationCode,
                },
                id,
              ) => (
                <>
                  <div key={id} className="page-break" />
                  <PrintingFor1
                    key={id}
                    attendanceNumber={attendanceNumber}
                    familyName={familyName}
                    givenName={givenName}
                    studentInvitationCode={studentInvitationCode}
                    schoolGrade={data.code.schoolGrade}
                    schoolClass={data.code.schoolClass}
                    code={data.code.code}
                  />
                </>
              ),
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}
