import { TFunction } from 'i18next'
import { ITeacherSchool } from '~/types/api/alpha/teachers'
import { getExtraQuestion } from '~/utils/questions/all-questions'
import { Question } from '~/utils/types'
import './questions-table.css'

type Props = {
  school: ITeacherSchool
  answers: Record<string, string | number> | undefined
  questionData: Question[]
  t: TFunction<'translation', undefined>
  filledEmptyCellsLength: number
}

const QuestionsTable = ({
  school,
  answers,
  questionData,
  t,
  filledEmptyCellsLength,
}: Props) => {
  // test with questions length more than one page
  // uncomment the line below to test
  // questionData = [...questionData, ...questionData.slice(10)]

  return (
    <div className="mt-4 w-full font-bold">
      <div className="w-full ml-2 text-black mb-2">{t('アンケート')}</div>

      <div className="grid grid-cols-4 questions-container">
        {questionData.map((question, index) => {
          let answer = ''

          const questionId = index + 1

          if (answers) {
            // check if selections question, depending on questions data (changed every year)
            const extraQuestion = getExtraQuestion(
              school.attributes.schoolName,
              question,
            )
            if (question.id === 45 && extraQuestion) {
              answer = extraQuestion[answers[questionId]]?.name ?? ''
            } else {
              for (const [key, value] of Object.entries(question.options)) {
                if (
                  answers[questionId] &&
                  value === Number(answers[questionId])
                ) {
                  answer = key
                  break
                }
              }
            }
          }
          // else answer = ''

          return (
            <div className="flex p-1 question-container" key={`${question.id}`}>
              <div className="question-name">{question.name}</div>

              <span className="w-8" />

              <div className="answer text-black">{answer}</div>
            </div>
          )
        })}

        {/* fill table */}
        {Array(filledEmptyCellsLength)
          .fill(0)
          .map((_, index) => (
            <div className="p-2 question-container" key={`${index}`} />
          ))}
      </div>
    </div>
  )
}

export default QuestionsTable
