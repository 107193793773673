import './StudentTestResultModal.css'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { LegacyRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import type { ITeacherSchool } from '~/types/api/alpha/teachers'
import type { Question } from '~/utils/types'
import PrintingTestResult from '../pages/test-result/components/ResultPrinting/PrintingTestResult'
import TestResultDetail from '../pages/test-result/components/ResultPrinting/TestResultDetail'
import { StudentTestResult } from '../pages/test-result/types/test-result'

type Props = {
  testYear: number
  student: StudentTestResult
  questionData: Question[]
  isShow: boolean
  setIsShow: (b: boolean) => void
  school: ITeacherSchool
  hasEnduranceRun: boolean
  questionFilledEmptyCellsLength: number
}

const StudentTestResultModal = ({
  testYear,
  student,
  isShow,
  setIsShow,
  school,
  questionData,
  hasEnduranceRun,
  questionFilledEmptyCellsLength,
}: Props) => {
  const { t } = useTranslation()

  const modalRef = useRef<HTMLDivElement>()

  const handlePrint = useReactToPrint({
    content: () => modalRef.current ?? null,
    documentTitle: `ALPHA${t('space個人結果表')}`,
  })

  const handleCancel = () => {
    setIsShow(false)
  }

  const modalProps = {
    visible: isShow,
    // 1324 = 1273 + 51
    width: 1324,
    onCancel: handleCancel,
    closeIcon: <CloseOutlined className="text-2xl color-blue-f" />,
    footer: null,
    className: 'float-right res-detail',
    style: { top: 0, paddingBottom: 0 },
  }

  return (
    <Modal {...modalProps}>
      <div className="res-detail-container">
        <TestResultDetail
          school={school}
          questionData={questionData}
          testYear={testYear}
          student={student}
          hasEnduranceRun={hasEnduranceRun}
          questionFilledEmptyCellsLength={questionFilledEmptyCellsLength}
        />
      </div>

      <div className="w-full flex justify-center">
        <Button className="w-60 rounded" type="primary" onClick={handlePrint}>
          {t('印刷')}
        </Button>
      </div>

      <div
        ref={modalRef as LegacyRef<HTMLDivElement>}
        className="print-component"
      >
        <PrintingTestResult
          school={school}
          questionData={questionData}
          testYear={testYear}
          student={student}
          hasEnduranceRun={hasEnduranceRun}
          questionFilledEmptyCellsLength={questionFilledEmptyCellsLength}
        />
      </div>
    </Modal>
  )
}

export default StudentTestResultModal
