import { isChangeValue } from '~/utils/string'

const isChangeGrip = (val, otherVal) =>
  isChangeValue(val?.gripRight1, otherVal?.gripRight1) ||
  isChangeValue(val?.gripRight2, otherVal?.gripRight2) ||
  isChangeValue(val?.gripLeft1, otherVal?.gripLeft1) ||
  isChangeValue(val?.gripLeft2, otherVal?.gripLeft2)

const isChangeSitUps = (val, otherVal) =>
  isChangeValue(val?.sitUps, otherVal?.sitUps)

const isChangeBending = (val, otherVal) =>
  isChangeValue(val?.bending1, otherVal?.bending1) ||
  isChangeValue(val?.bending2, otherVal?.bending2)

const isChangeSideJump = (val, otherVal) =>
  isChangeValue(val?.sideJump1, otherVal?.sideJump1) ||
  isChangeValue(val?.sideJump2, otherVal?.sideJump2)

const isChangeEnduranceRun = (val, otherVal) =>
  isChangeValue(val?.runningTime, otherVal?.runningTime) ||
  isChangeValue(val?.runningTimeSeconds, otherVal?.runningTimeSeconds)

const isChangeShuttleRun = (val, otherVal) =>
  isChangeValue(val?.shuttleRunCount, otherVal?.shuttleRunCount)

const isChangeSprintRun = (val, otherVal) =>
  isChangeValue(val?.sprintRunSeconds, otherVal?.sprintRunSeconds)

const isChangeStandingJump = (val, otherVal) =>
  isChangeValue(val?.standingJump1, otherVal?.standingJump1) ||
  isChangeValue(val?.standingJump2, otherVal?.standingJump2)

const isChangeHandballThrow = (val, otherVal) =>
  isChangeValue(val?.handballThrow1, otherVal?.handballThrow1) ||
  isChangeValue(val?.handballThrow2, otherVal?.handballThrow2)

const isChangeSize = (val, otherVal) =>
  isChangeValue(val?.weight, otherVal?.weight) ||
  isChangeValue(val?.height, otherVal?.height)

export const checkChange = (values, preValues) => {
  const gripTest = isChangeGrip(values, preValues?.grip)
  const sitUpsTest = isChangeSitUps(values, preValues?.sitUps)
  const bendingTest = isChangeBending(values, preValues?.bending)
  const sideJumpTest = isChangeSideJump(values, preValues?.sideJump)
  const enduranceRunTest = isChangeEnduranceRun(values, preValues?.enduranceRun)
  const shuttleRunTest = isChangeShuttleRun(values, preValues?.shuttleRun)
  const sprintRunTest = isChangeSprintRun(values, preValues?.sprintRun)
  const standingJumpTest = isChangeStandingJump(values, preValues?.standingJump)
  const handballThrowTest = isChangeHandballThrow(
    values,
    preValues?.handballThrow,
  )
  const sizeTest = isChangeSize(values, preValues?.sizeTest)

  return {
    gripTest,
    sitUpsTest,
    bendingTest,
    sideJumpTest,
    enduranceRunTest,
    shuttleRunTest,
    sprintRunTest,
    standingJumpTest,
    handballThrowTest,
    sizeTest,
    notChange:
      !gripTest &&
      !sitUpsTest &&
      !bendingTest &&
      !sideJumpTest &&
      !enduranceRunTest &&
      !shuttleRunTest &&
      !sprintRunTest &&
      !standingJumpTest &&
      !handballThrowTest &&
      !sizeTest,
  }
}
