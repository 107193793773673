import { clearLocalStorage } from '~/school-utils/storages'
import Api from '~/utils/api'

export const callLogoutApiForSchool = async () => {
  try {
    await Api.get('/alpha/v1/school/logout')
  } catch (error) {
    console.error('when call logout for school, error:', error)
    throw error
  }

  clearLocalStorage()
}
